import { FC } from "react";
import styled from "styled-components";
import theme from "../theme";
import {
  convertAndFormatHistogramData,
  moneyFormat,
} from "../simulations/utils";
import { useSelector } from "react-redux";
import { metricMap } from "../constants";
import { MultipleSimulationResult } from "../types";
import { RootState } from "../state";
import diplomaIcon from "../assets/icons/diploma-blue.svg";
import briefcaseIcon from "../assets/icons/briefcase-blue.svg";
import Histogram from "../components/graphs/Histogram";

const Style = styled.div`
 box-sizing: border-box;
  padding: 50px;
  border: 2px ${theme.colors.secondary} solid;
  border-radius: 0px 0px 10px 10px;

  .grouped-text {
    font-size: 18px;
  }

  .risk-sim-input {
    border: 2px ${theme.colors.lightIndigo} solid;
    text-align: left;
    width: 40%;
    padding: 10px;
    border-radius: 15px;
    font-size: 15px;
    line-height: 130%;
    display: flex;
    justify-contents: center;
    align-items: center;
  }
    
  .School-Job-Description-Container {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .histogram-container {
    display: flex;
    justify-content: space-between;
  }

  .histogram-area {
    height: 300px;
    margin: 0 auto;
    padding-top: 20px;
  }

  .Total-Loan-Balance {
    color: ${theme.colors.secondary};
  }

  .Average-Starting-Salary {
    color: ${theme.colors.graphColors.turquoise};
  }

  .diploma-icon {
    height: 30px;
    width: 30px;
   
    padding-left 15px;
    padding-right 15px;
  }

  .briefcase-icon {
    height: 30px;
    width: 30px;
   
    padding-left 15px;
    padding-right 15px;
  }
  
  .tip-bubble {
    border: 2px ${theme.colors.secondary} solid;
    z: 10;
    text-align: left;
    width: 35%;
    padding: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 130%;
    margin: auto;
    margin-bottom: 10px;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    padding: 15px;
    
    .tip-bubble {
      font-size: 10px;
      width: 40%
    }

    p {
    font-size: 10px;
    }

    b {
    font-size: 10px;
    }

    .briefcase-icon {
      padding: 2px;
      padding-right 7px;
    }

    .diploma-icon {
      padding: 2px;
      padding-right 7px;
    }

    .risk-sim-input {
      width: 47%;
    }
  }
`;
const selectMultipleSimulationState = (state: RootState) =>
  state.multipleSimulation;
const selectFinancingOptionsState = (state: RootState) =>
  state.financingOptions;

const RiskSimSummary: FC = () => {
  const {
    results: financingOptionsResults,
    selectedJob,
    selectedSchool,
  } = useSelector(selectFinancingOptionsState);

  const selectedCollegeFinancingOptions = financingOptionsResults.find(
    (school) => school.collegeId === selectedSchool.collegeId,
  );

  const { results, median } = useSelector(selectMultipleSimulationState);

  const selectedMetricKey = metricMap["Average annual income"];

  const metricData =
    results.length > 0
      ? results.map(
          (result: { [x: string]: any }) =>
            result[selectedMetricKey.key as keyof MultipleSimulationResult],
        )
      : [];

  const formattedData = convertAndFormatHistogramData(metricData, 1000);

  let largestBucket = { x: "", y: 0 };
  formattedData.forEach((bucket) => {
    if (bucket.likelihood > largestBucket.y) {
      largestBucket.x = bucket.amount;
      largestBucket.y = bucket.likelihood;
    }
  });

  return (
    <Style>
      <p>Let's say you pursued this path:</p>
      <div className="School-Job-Description-Container">
        <div className="risk-sim-input">
          <img className="diploma-icon" src={diplomaIcon} alt="icon" />
          <div className="grouped-text">
            <p>
              Attended <b>{selectedSchool.name}</b>
            </p>

            <p>
              Total loan balance:{" "}
              <b className="Total-Loan-Balance">
                $
                {selectedCollegeFinancingOptions
                  ? moneyFormat(
                      Number(selectedCollegeFinancingOptions.totalLoanAmount),
                    )
                  : "0"}
              </b>
            </p>
          </div>
        </div>
        <div className="risk-sim-input">
          <img className="briefcase-icon" src={briefcaseIcon} alt="icon" />
          <div className="grouped-text">
            <p>
              Worked in the field of{" "}
              <b>{selectedJob ? selectedJob.jobTitle.toString() : "n/a"}</b>
            </p>

            <p>
              Average starting salary:{" "}
              <b className="Average-Starting-Salary">
                $
                {selectedJob
                  ? moneyFormat(selectedJob.avgStartingSalary).toString()
                  : "n/a"}
              </b>
            </p>
          </div>
        </div>
      </div>
      <p>
        This is what your life could look like{" "}
        <b>20 years after graduating from college</b>:
      </p>

      <div className="annual-title-container">
        <h2 className="summary-card-title outlined-stat orange-border">
          Median Annual Salary: ${moneyFormat(median.avgAnnualIncome)}
        </h2>
      </div>
      <p>
        Your annual salary is the amount of money that your employer would pay
        you over a year.
      </p>
      <p>
        The graph below shows the different probabilities of you earning within
        each salary bracket after running 1,000 simulations.
      </p>
      <div className="histogram-area">
        <Histogram
          data={formattedData}
          colors={[theme.colors.graphColors.lavender]}
          xAxisKey="amount"
          yAxisKeys={["likelihood"]}
          showYAxis={true}
          xAxisLabel="Annual salary"
          yAxisLabel="Likelihood"
          showLegend={false}
          showDollarSign={true}
          enableLabel={window.innerWidth >= theme.breakpoints.large}
          maxYValue={largestBucket.y}
        />
      </div>
      <p>
        In <b>{(largestBucket.y * 100).toFixed(0)}%</b> of the 1,000
        simulations, you earned an average annual salary of{" "}
        <b>${largestBucket.x}</b> over 20 years after graduating from college.
        This was the most likely outcome out of all 1,000 simulations.
      </p>
    </Style>
  );
};

export default RiskSimSummary;

import { createSlice } from "@reduxjs/toolkit";
import { SingleSimulationState } from "../types";

const initialState: SingleSimulationState = {
  selectedAnnualLoanAmount: 0,
  results: {
    collegePeriod: {
      yearsInSchool: 0,
      graduated: false,
    },
    jobPeriod: {
      yearsEmployedInJobRelatedToDegree: 0,
      yearsEmployedInJobUnrelatedToDegree: 0,
      yearsUnemployed: 0,
    },
    summaryStats: {
      idrpaymentPeriod: 0,
      totalIdrPayment: 0,
      totalStandPayment: 0,
      averageMonthlyIDR: 0,
      averageMonthlyStand: 0,
      idrexpenses: 0,
      idrforgivenBalance: 0,
      personalExpensesOverTime: [
        {
          annualSalary: 0,
          annualOtherExpenses: 0,
          annualStudentLoanPayment: 0,
          annualTaxes: 0,
          annualSavings: 0,
        },
      ],
      personalIDRExpensesOverTime: [
        {
          annualSalary: 0,
          annualOtherExpenses: 0,
          annualStudentLoanPayment: 0,
          annualTaxes: 0,
          annualSavings: 0,
        },
      ],
      totalEarnings: 0,
      totalExpenses: 0,
      totalAssetsOverTime: [{ x: 0, y: 0 }],
      totalEarningsOverTime: [{ x: 0, y: 0 }],
      totalExpensesOverTime: [{ x: 0, y: 0 }],
      totalIDRExpensesOverTime: [{ x: 0, y: 0 }],
      milestonesOverTime: [{ x: 0, milestone: "" }],
    },
  },
};

const slice = createSlice({
  name: "singleSimulation",
  initialState,
  reducers: {
    updateAnnualLoan: (state, action) => {
      state.selectedAnnualLoanAmount = action.payload;
    },
    fetchSingleSimulationResults: (state, action) => {
      state.results = action.payload;
    },
  },
});

export const { updateAnnualLoan, fetchSingleSimulationResults } = slice.actions;

export default slice.reducer;

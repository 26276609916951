import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../state";
import theme from "../theme";
import { FC } from "react";
import { moneyFormat } from "../simulations/utils";
import SimpleBarGraph from "../components/graphs/HorizontalSimpleBarGraph";
import { financialSourceLegendMap, financingOptionsValues } from "../constants";
import { FinancingOptions } from "../types";

const Style = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 48px;
  padding: 50px;
  box-sizing: border-box;
  border: 2px ${theme.colors.secondary} solid;
  border-radius: 0px 0px 10px 10px;

  .graph {
    width: 100%;
    height: 70px;
  }

  .graph-labels {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    margin-top: 36px;

    .graph-label {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .square {
    height: 12px;
    width: 12px;
  }

  .familyContributionAmount {
    background: ${theme.colors.graphColors.turquoise};
  }

  .parentPlusLoanAmount {
    background: ${theme.colors.graphColors.lavender};
  }

  .studentLoanAmount {
    background: ${theme.colors.graphColors.redOrange};
  }

  .totalSubsidized {
    background: ${theme.colors.graphColors.eggplantPurple};
  }

  .totalUnsubsidized,
  .otherAmount {
    background: ${theme.colors.graphColors.mediumGreen};
  }

  .totalPrivate {
    background: ${theme.colors.graphColors.indigo};
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    padding: 25px;
    font-size: 12px;
    border: none;
  }
`;

interface StudentLoanSummaryProps {
  selectedCollegeId: string;
}

const FinancingSummary: FC<StudentLoanSummaryProps> = ({
  selectedCollegeId,
}) => {
  const collegeFinancialSources = useSelector(
    (state: RootState) => state.financingOptions.collegeFinancialSources,
  );
  const selectedCollegeFinancingResult = collegeFinancialSources.find(
    (school) => school.collegeId === selectedCollegeId,
  );
  const financingOptions = useSelector(
    (state: RootState) => state.financingOptions.financingOptions,
  );
  const selectedFinancingOptions = financingOptions.find(
    (school) => school.collegeId === selectedCollegeId,
  );

  const financingGraphLabels = Object.entries(financialSourceLegendMap).filter(
    ([key, value]) => financingOptionsValues.includes(key),
  );

  const netPrice = selectedCollegeFinancingResult
    ? selectedCollegeFinancingResult.annualNetPrice
    : 0;

  return (
    <Style>
      <div className="summary-text-container">
        <div className="annual-title-container">
          <div className="summary-card-title outlined-stat blue-border">
            Net Price: ${moneyFormat(netPrice)}
          </div>
        </div>
        <p>
          You can pay the <strong>net price</strong> using different financial
          sources, including your family contribution (how much your family is
          willing to pay for your college), Parent PLUS loans, student loans,
          and other options like scholarships and outside grants.
        </p>
      </div>

      {selectedFinancingOptions && netPrice !== 0 && (
        <div className="summary-graph-container">
          <h4>Breakdown by Financial Source (each year)</h4>

          <div className="graph-labels">
            {financingGraphLabels.map(([key, value]) => {
              return (
                <div className="graph-label">
                  <div className={`square ${key}`}></div>
                  {value}: $
                  {moneyFormat(
                    Number(
                      selectedFinancingOptions[key as keyof FinancingOptions],
                    ),
                  )}
                </div>
              );
            })}
          </div>
          <div className="graph">
            <SimpleBarGraph
              data={[selectedFinancingOptions]}
              layout="horizontal"
              xAxisKey="schoolName"
              yAxisKeys={[
                "familyContributionAmount",
                "otherAmount",
                "parentPlusLoanAmount",
                "studentLoanAmount",
              ]}
              showYAxis={true}
              legendLabelMap={financialSourceLegendMap}
              xAxisLabel=""
              yAxisLabel=""
              legendWidth={180}
              colors={[
                theme.colors.graphColors.turquoise,
                theme.colors.graphColors.mediumGreen,
                theme.colors.graphColors.lavender,
                theme.colors.graphColors.redOrange,
              ]}
            />
          </div>
        </div>
      )}
    </Style>
  );
};

export default FinancingSummary;

import styled from "styled-components";
import theme from "../theme";

interface ButtonStyleProps {
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  size?: string;
  height?: string;
  fontSize?: string;
}

const Style = styled.div<ButtonStyleProps>`
  .basic-button {
    padding: 16px 32px;
    border: 3px solid ${(props) => props.borderColor || theme.colors.primary};
    color: ${(props) => props.textColor || theme.colors.neutrals.white};
    background: ${(props) => props.bgColor || theme.colors.primary};
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 700;
    width: ${(props) => props.size};
    height: ${(props) => props.height};
    font-size: ${(props) => props.fontSize || "18px"};
  }

  .basic-button:active {
    opacity: 0.8;
    transition: 0.15s;
  }
  a {
    text-decoration: none;
  }

  .blue-button {
    background: #031d44;
  }

  .right-icon {
    margin-left: 8px;
  }

  .left-icon {
    margin-right: 8px;
  }
  .button-text {
    text-align: center;
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    .left-icon {
      // margin-right: 0px;
    }
    .basic-button {
      padding: 2.5vw 5vw;
      font-size: 15px;
      width: 100%;
      border: 2.5px solid
        ${(props) => props.borderColor || theme.colors.primary};
    }
  }
`;

interface ButtonProps {
  className?: string;
  styleClassName?: string;
  buttonLink?: string;
  buttonText?: string;
  buttonLeftIcon?: string;
  buttonRightIcon?: string;
  handleOnClick?: () => void;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  size?: string;
  height?: string;
  fontSize?: string;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  styleClassName,
  buttonText,
  buttonLeftIcon,
  buttonRightIcon,
  handleOnClick,
  borderColor,
  bgColor,
  textColor,
  size,
  height,
  fontSize,
}) => {
  return (
    <Style
      className={styleClassName}
      bgColor={bgColor}
      borderColor={borderColor}
      textColor={textColor}
      size={size}
      height={height}
      fontSize={fontSize}
    >
      <button
        className={`basic-button ${className ? className : ""}`}
        onClick={handleOnClick}
      >
        {buttonLeftIcon && (
          <img src={buttonLeftIcon} alt="button-icon" className="left-icon" />
        )}
        <div className="button-text">{buttonText}</div>
        {buttonRightIcon && (
          <img src={buttonRightIcon} alt="button-icon" className="right-icon" />
        )}
      </button>
    </Style>
  );
};

Button.defaultProps = {
  buttonLink: undefined,
  buttonText: "Button",
  handleOnClick: () => {},
};

export default Button;

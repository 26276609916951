import { useSelector } from "react-redux";
import { RootState } from "../state";
import SummaryCard from "./SummaryCard";
import CollegeCostSummary from "./CollegeCostSummary";
import styled from "styled-components";
import theme from "../theme";
import StudentLoanSummary from "./StudentLoanSummary";
import RiskSimSummary from "./RiskSimSummary";
import { CollegeFinancialSources } from "../types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrivateLoanSummary from "./PrivateLoanSummary";
import OtherResourcesSummary from "./OtherResourcesSummary";
import FederalLoanRepaymentSummary from "./FederalLoanRepaymentSummary";
import FinancingSummary from "./FinancingSummary";

const Style = styled.div`
  .outlined-stat {
    text-align: center;
    max-width: fit-content;
    border-radius: 5px;
    padding: 12px;
  }

  .blue-border {
    border: 2px ${theme.colors.graphColors.indigo} solid;
  }

  .orange-border {
    border: 2px ${theme.colors.secondary} solid;
  }

  .graph {
    width: 100%;
    height: 80px;
  }

  .annual-title-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    width: 100%;

    @media screen and (max-width: ${theme.breakpoints.xlarge}px) {
      flex-direction: column;
    }

    @media print {
      flex-direction: column;
    }
  }

  .summary-content {
    box-sizing: border-box;
    padding: 32px 100px 50px 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @media print {
      padding: 12px 0 0 0;
      gap: 12px;
    }
  }

  .same-row {
    display: flex;
    gap: 10px;
    align-items: stretch;

    .column {
      width: 50% !important;
      min-height: 100%;
    }
  }

  .summary-card-title {
    font-size: 22px;
    font-weight: 700;
  }

  .summary-text-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .page-break {
    page-break-after: always;
  }

  .section-title {
    color: ${theme.colors.primary};
    font-size: 28px;
    margin-bottom: 0px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: 5px solid ${theme.colors.primary};

    @media screen and (max-width: ${theme.breakpoints.medium}px) {
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.large}px) {
    .same-row {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;

      .column {
        width: 100% !important;
      }
    }
  }

  @media print {
    .same-row {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0px;

      .column {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    .summary-content {
      padding: 0px;
      margin: 0px 0px 16px 0px;
    }

    .column {
      gap: 0px;
    }
  }
`;

const SummaryContent = () => {
  const navigate = useNavigate();
  const selectFinancingOptionsState = (state: RootState) =>
    state.financingOptions;
  const selectCollegeCostsState = (state: RootState) => state.collegeCosts;
  const selectMultipleSimulationState = (state: RootState) =>
    state.multipleSimulation;

  const {
    collegeFinancialSources,
    selectedJob,
    selectedSchool,
    results: financingOptionsResults,
  } = useSelector(selectFinancingOptionsState);

  const { results: collegeCostsResults, showNetPriceResults } = useSelector(
    selectCollegeCostsState,
  );

  const { results: multipleSimulationResults } = useSelector(
    selectMultipleSimulationState,
  );

  const { activePages } = useSelector((state: RootState) => state.navigation);

  const selectedFinancingOptionsResult = financingOptionsResults.find(
    (school) => school.collegeId === selectedSchool.collegeId,
  );

  useEffect(() => {
    if (
      collegeCostsResults.length === 0 ||
      !selectedJob ||
      selectedSchool.collegeId === "" ||
      financingOptionsResults.length !== collegeCostsResults.length ||
      multipleSimulationResults.length === 0 ||
      activePages.length < 3
    ) {
      navigate("/");
    }
  }, [
    navigate,
    selectedJob,
    activePages.length,
    multipleSimulationResults.length,
    selectedSchool.collegeId,
    financingOptionsResults.length,
    collegeCostsResults.length,
  ]);

  return (
    <Style>
      {collegeFinancialSources.map((collegeFinancialSource, index) => (
        <div
          className={`${
            index !== collegeFinancialSources.length - 1 ? "page-break" : ""
          }`}
        >
          <div className="section-title">
            {index + 1 + ".  " + collegeFinancialSource.schoolName}
          </div>
          <div className="summary-content">
            <div className="same-row">
              <div className="column">
                <SummaryCard
                  title="College Costs"
                  children={
                    <CollegeCostSummary
                      stickerPrice={collegeFinancialSource.totalAnnualCost}
                      netPrice={collegeFinancialSource.annualNetPrice}
                      financialSources={
                        collegeFinancialSources.find(
                          (college) =>
                            college.collegeId ===
                            collegeFinancialSource.collegeId,
                        ) as CollegeFinancialSources
                      }
                      hasCalculatedNetPrice={showNetPriceResults}
                    />
                  }
                />
              </div>

              <div className="column">
                <SummaryCard
                  title="Financing"
                  children={
                    <FinancingSummary
                      selectedCollegeId={collegeFinancialSource.collegeId}
                    />
                  }
                />
              </div>
            </div>
            <SummaryCard
              title="Student Loans"
              children={
                <StudentLoanSummary
                  selectedCollegeId={collegeFinancialSource.collegeId}
                />
              }
            />
          </div>
        </div>
      ))}
      <div className="section-title">Look Into the Future</div>
      <div className="summary-content">
        <SummaryCard title="Risk Simulation" children={<RiskSimSummary />} />
        {selectedFinancingOptionsResult &&
          selectedFinancingOptionsResult?.totalLoanAmount > 0 && (
            <SummaryCard
              title="Federal Loan Repayment"
              children={
                <FederalLoanRepaymentSummary
                  selectedCollegeId={selectedSchool.collegeId}
                />
              }
            />
          )}
        {selectedFinancingOptionsResult &&
          selectedFinancingOptionsResult?.totalLoanAmount > 0 && (
            <SummaryCard
              title="Private Loan Repayment"
              children={<PrivateLoanSummary />}
            />
          )}
        <SummaryCard
          title="Other Resources"
          children={<OtherResourcesSummary />}
        />
      </div>
    </Style>
  );
};

export default SummaryContent;

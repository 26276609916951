import { createSlice } from "@reduxjs/toolkit";
import { FinancingOptionsState } from "../types";

const initialState: FinancingOptionsState = {
  showResults: false,
  financingOptions: [],
  selectedJob: undefined,
  selectedSchool: {
    collegeId: "",
    name: "",
  },
  showSimulationResults: false,
  collegeFinancialSources: [],
  jobDropdownOptions: {
    majors: [],
    jobs: [],
  },
  results: [],
  loanAccumulationStepIndex: 0,
  currentYear: 1,
  totalNumberOfYears: 4,
  loanBalanceSoFar: {
    total: 0,
    federalSubsidized: 0,
    federalUnsubsidized: 0,
    private: 0,
  },
  currentCollegeFinancialSources: {
    collegeId: "",
    schoolName: "",
    federalAid: 0,
    stateAndLocalAid: 0,
    institutionalAid: 0,
    pellGrantAmount: 0,
    otherGovernmentalAid: 0,
    parentPlusLoanAmount: 0,
    familyContribution: 0,
    other: 0,
    loanAmount: 0,
    annualNetPrice: 0,
    averageAnnualNetPrice: 0,
    averageFederalAid: 0,
    averageStateAndLocalAid: 0,
    averageInstitutionalAid: 0,
    averageTotalAid: 0,
    totalAnnualCost: 0,
    totalCollegeCost: 0,
    totalLoanAmount: 0,
    inState: false,
    publicSchool: false,
    coefficientAvailable: false,
  },
  currentFinancingOptionsResult: {
    collegeId: "",
    totalLoanAmount: 0,
    totalSubsidized: 0,
    totalUnsubsidized: 0,
    totalPrivate: 0,
    financingInfoStandard: {}, // TODO: remove this
    financingInfoIDR: {}, // TODO: remove this
    annualLoanAccumulationData: [],
  },
  selectedFinancialOptionsResult: {
    collegeId: "",
    studentAidIndexAmount: "0",
    pellGrantAmount: "0",
    parentPlusLoanAmount: "0",
    familyContributionAmount: "0",
    otherAmount: "0",
    studentLoanAmount: "0",
    parentIncome: "0",
    userHasUpdatedThis: false,
  },
  saiFormValues: {
    stateOfResidency: "",
    householdSize: "",
    parentMaritalStatus: "",
    parentIncome: "",
    studentIncome: "",
    studentScholarshipsOrGrants: "",
    parentAssets: "",
    studentAssets: "",
  },
  customSAIValue: false,
};

const slice = createSlice({
  name: "financingOptions",
  initialState,
  reducers: {
    setCurrentCollegeFinancialSources: (state, action) => {
      state.currentCollegeFinancialSources = action.payload;
    },
    setCurrentFinancingOptionsResult: (state, action) => {
      state.currentFinancingOptionsResult = action.payload;
    },
    setSelectedFinancingSourcesResult: (state, action) => {
      state.selectedFinancialOptionsResult = action.payload;
    },
    setShowResults: (state, action) => {
      state.showResults = action.payload;
    },
    addNewFinancingOptionBySchoolId: (state, action) => {
      state.financingOptions.push({
        collegeId: action.payload,
        studentAidIndexAmount:
          state.financingOptions[0]?.studentAidIndexAmount || "0",
        pellGrantAmount: state.financingOptions[0]?.pellGrantAmount || "0",
        parentPlusLoanAmount: "0",
        familyContributionAmount: "0",
        otherAmount: "0",
        studentLoanAmount: "0",
        parentIncome: "0",
        userHasUpdatedThis: false,
      });
    },
    updateFinancingOptionsBySchoolId: (state, action) => {
      const { collegeId, dataToUpdate, userUpdatedThisValue } = action.payload;
      const index = state.financingOptions.findIndex(
        (school) => school.collegeId === collegeId,
      );
      if (index !== -1) {
        // Use the spread operator to update pellGrantAmount
        state.financingOptions[index] = {
          ...state.financingOptions[index],
          ...dataToUpdate,
          userHasUpdatedThis: userUpdatedThisValue || false,
        };
      }
    },
    removeFinancingOptionsBySchoolId: (state, action) => {
      const schoolId = action.payload;
      state.financingOptions = state.financingOptions.filter(
        (financingOption) => financingOption.collegeId !== schoolId,
      );
    },
    getAllJobDropdownOptions: (state, action) => {
      state.jobDropdownOptions = action.payload;
    },
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
    setSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload;
    },
    setCollegeFinancialSources: (state, action) => {
      state.collegeFinancialSources = action.payload;
    },
    removeCollegeFinancialSources: (state, action) => {
      state.collegeFinancialSources = state.collegeFinancialSources.filter(
        (college) => college.collegeId !== action.payload,
      );
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
    updateLoanAccumulationStepIndex: (state, action) => {
      state.loanAccumulationStepIndex = action.payload;
    },
    setCurrentYear: (state, action) => {
      state.currentYear = action.payload;
    },
    updateLoanBalanceSoFar: (state, action) => {
      state.loanBalanceSoFar = action.payload;
    },
    updateTotalNumberOfYears: (state, action) => {
      state.totalNumberOfYears = action.payload;
    },
    setShowSimulationResults: (state, action) => {
      state.showSimulationResults = action.payload;
    },
    setSAIFormValues: (state, action) => {
      state.saiFormValues = action.payload;
    },
    setCustomSAIValue: (state, action) => {
      state.customSAIValue = action.payload;
    },
  },
});

export const {
  setShowResults,
  setResults,
  getAllJobDropdownOptions,
  setCollegeFinancialSources,
  setSelectedJob,
  setSelectedSchool,
  addNewFinancingOptionBySchoolId,
  updateFinancingOptionsBySchoolId,
  removeCollegeFinancialSources,
  updateLoanAccumulationStepIndex,
  setCurrentYear,
  updateTotalNumberOfYears,
  updateLoanBalanceSoFar,
  setCurrentCollegeFinancialSources,
  setCurrentFinancingOptionsResult,
  setSelectedFinancingSourcesResult,
  setShowSimulationResults,
  removeFinancingOptionsBySchoolId,
  setSAIFormValues,
  setCustomSAIValue,
} = slice.actions;

export default slice.reducer;

import styled from "styled-components";
import rightArrow from "../assets/icons/arrow-right-blue.svg";
import restartButton from "../assets/icons/restart-orange.svg";
import theme from "../theme";
import ShootingStarIcon from "../assets/icons/shooting-star-blue.svg";

import { convertAndFormatHistogramData, moneyFormat } from "./utils";
import Dropdown from "../components/Dropdown";
import { useState } from "react";
import { Button } from "../components/Button";
import Histogram from "../components/graphs/Histogram";
import { metricMap } from "../constants";
import { MultipleSimulationResult } from "../types";
import TextCallout from "../components/TextCallout";
import { useSelector } from "react-redux";
import { RootState } from "../state";

const Style = styled.div`
  .simulations-summary-heading {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
    margin-top: 24px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
  }

  .simulations-summary-content {
    margin-bottom: 24px;
  }

  .graph-container {
    width: 100%;
    height: 400px;

    @media (max-width: ${theme.breakpoints.medium}px) {
      width: 90vw;
    }
  }

  .descriptions {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .green {
    color: ${theme.colors.graphColors.turquoise};
  }

  .orange {
    color: ${theme.colors.graphColors.redOrange};
  }

  .purple {
    color: ${theme.colors.graphColors.lavender};
  }

  .red {
    color: ${theme.colors.graphColors.redOrange};
  }

  .blue {
    color: ${theme.colors.primary};
  }

  .simulation-explanation {
    font-size: 18px;
    margin-bottom: 32px;
    margin-top: 24px;
  }

  .simulation-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .simulations-results-view {
    font-size: 12px;
    margin-bottom: 24px;
  }

  .view-toggle {
    font-weight: 600;
    color: ${theme.colors.primary};
    cursor: pointer;
  }

  .simulations-results-list {
    height: 40px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
  }

  .results-list-item {
    width: 30%;
  }

  .instruction-text {
    margin-bottom: 36px;
  }
`;

const selectMultipleSimulationState = (state: RootState) =>
  state.multipleSimulation;

const MultipleSimulationView = () => {
  let formatter = Intl.NumberFormat("en", { notation: "compact" });
  const { results } = useSelector(selectMultipleSimulationState);

  const [simulationNumber, changeSimulationNumber] = useState(1);
  const [selectedMetric, changeSelectedMetric] = useState(
    "Average annual take-home pay",
  );
  const [showAllSimulationResults, toggleShowAllSimulationResults] =
    useState(false);

  const selectedMetricKey = metricMap[selectedMetric];

  const metricData =
    results.length > 0
      ? results.map(
          (result) =>
            result[selectedMetricKey.key as keyof MultipleSimulationResult],
        )
      : [];

  const formattedData = convertAndFormatHistogramData(
    metricData,
    simulationNumber,
  );

  const selectedSimulation = formattedData.find((datum) =>
    datum.bin.includes(metricData[simulationNumber - 1]),
  );

  return (
    <Style>
      <TextCallout
        text={
          <>
            Running this simulation 1,000 times will help you approximate what
            the total probability distribution (aka the{" "}
            <b>likelihood of each outcome</b>), would look like.
          </>
        }
      />

      <div className="simulations-summary-heading">
        <img src={ShootingStarIcon} alt="Shooting star icon" /> Total number of
        simulations so far:<b className="blue">{simulationNumber}</b>
      </div>

      <div className="simulations-results-view">
        <div
          className="view-toggle"
          onClick={() =>
            toggleShowAllSimulationResults(!showAllSimulationResults)
          }
        >
          {showAllSimulationResults ? "- HIDE" : "+ SHOW"} ALL RESULTS
        </div>
        {showAllSimulationResults && (
          <div className="simulations-results-list">
            {metricData.slice(0, simulationNumber).map((result, i) => {
              return (
                <div className="results-list-item">
                  Simulation #{i + 1}: {selectedMetricKey.showDollarSign && "$"}
                  {moneyFormat(result)}
                  {selectedMetricKey.showYears && " year(s)"}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="simulations-summary-content">
        <Dropdown
          fieldTitle=""
          fieldValue={selectedMetric}
          dropdownOptions={Object.keys(metricMap)}
          handleChange={changeSelectedMetric}
        />
        {selectedMetricKey.description && (
          <div className="instruction-text">
            <b>{selectedMetric}</b>: {selectedMetricKey.description}
          </div>
        )}
        <div className="graph-container">
          <Histogram
            data={formattedData}
            colors={selectedMetricKey.barColor}
            xAxisKey="amount"
            yAxisKeys={["likelihood"]}
            showYAxis={true}
            xAxisLabel=""
            yAxisLabel="Likelihood"
            showLegend={false}
            showDollarSign={selectedMetric !== "Cumulative time unemployed"}
          />
        </div>

        <div className="simulation-explanation">
          In Simulation #{simulationNumber}, you had{" "}
          <b className={selectedMetricKey.textColor}>
            {selectedMetricKey.showDollarSign && "$"}
            {moneyFormat(metricData[simulationNumber - 1])}{" "}
            {selectedMetricKey.showYears && " year(s)"}
          </b>{" "}
          in {selectedMetric.toLowerCase()} after 20 years.
          <br />
          <br />
          <b className={selectedMetricKey.textColor}>
            {selectedSimulation
              ? (selectedSimulation.likelihood * 100).toFixed(0)
              : "0"}
            %
          </b>{" "}
          of the outcomes so far are in the{" "}
          <b className={selectedMetricKey.textColor}>
            {selectedMetricKey.showDollarSign && "$"}
            {selectedSimulation &&
            selectedSimulation.bin.x0 &&
            selectedSimulation.bin.x1
              ? `${formatter.format(
                  selectedSimulation.bin.x0,
                )} - ${formatter.format(selectedSimulation.bin.x1)}`
              : "0"}{" "}
            {selectedMetricKey.showYears && " year(s)"}
          </b>{" "}
          range because{" "}
          {simulationNumber === 1
            ? `this simulation`
            : `${selectedSimulation?.bin.length} of ${simulationNumber} simulations`}{" "}
          resulted in {selectedMetricKey.showDollarSign && "$"}
          {selectedSimulation &&
          selectedSimulation.bin.x0 &&
          selectedSimulation.bin.x1
            ? `${formatter.format(
                selectedSimulation.bin.x0,
              )} - ${formatter.format(selectedSimulation.bin.x1)}`
            : "0"}{" "}
          {selectedMetricKey.showYears && "year(s)"} in{" "}
          {selectedMetric.toLowerCase()}.
        </div>
      </div>
      <div className="simulation-buttons">
        {simulationNumber < metricData.length && (
          <Button
            buttonText={
              window.innerWidth < theme.breakpoints.medium
                ? "Next"
                : "Next simulation"
            }
            handleOnClick={() => changeSimulationNumber(simulationNumber + 1)}
            buttonRightIcon={rightArrow}
            bgColor={theme.colors.neutrals.white}
            borderColor={theme.colors.primary}
            textColor={theme.colors.primary}
          />
        )}
        {simulationNumber < metricData.length && (
          <Button
            buttonText={
              window.innerWidth < theme.breakpoints.medium
                ? "Run 1,000"
                : "Run 1,000 simulations"
            }
            handleOnClick={() => changeSimulationNumber(metricData.length)}
            buttonRightIcon={rightArrow}
            bgColor={theme.colors.neutrals.white}
            borderColor={theme.colors.primary}
            textColor={theme.colors.primary}
          />
        )}

        {simulationNumber === metricData.length && (
          <Button
            buttonText="Restart"
            handleOnClick={() => changeSimulationNumber(1)}
            buttonLeftIcon={restartButton}
            bgColor={theme.colors.neutrals.white}
            borderColor={theme.colors.secondary}
            textColor={theme.colors.secondary}
          />
        )}
      </div>
    </Style>
  );
};

export default MultipleSimulationView;

import styled from "styled-components";
import theme from "../theme";
import { RootState } from "../state";
import Button from "../components/Button";
import SearchBar from "../components/SearchBar";
import { keyframes } from "styled-components";
import FullRideCollegeInformation from "../components/FullRideInformation";
import { useEffect, useMemo, useState } from "react";
import FullRideFilter from "../components/FullRideFilter";
import { addNewFinancingOptionBySchoolId } from "../student-loans/financing-options-slice";
import { useDispatch, useSelector } from "react-redux";
import { FullRideSchool } from "../types";

interface CollegeCostsSAIProps {
  showOverlay: boolean;
  hideOverlay: () => void;
  fetchNewSchoolData: (collegeId: string | string[]) => void;
}

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Style = styled.div`
  width: 100%;

  ul {
    list-style-type: none;
  }
  .title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 3rem;
    line-height: 1.2;
    text-align: center;
    margin-top: 0px;
  }

  .message {
    background-color: ${theme.colors.creamOrange};
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 3rem;
    box-sizing: border-box;
  }

  .collegeList {
    padding-left: 0px;
    overflow-y: scroll;
    max-height: 400px;
  }

  .fullRideCollegeListContainer {
  }

  .popUpContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
  }

  .hr {
    margin-top: 20px;
  }

  .addButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    animation: ${slideIn} 0.3s ease-out;
  }

  .animated {
    animation: ${slideIn} 0.3s ease-out;
  }

  .search-result-message {
    text-align: center;
  }

  .filter-popup {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  .filter-popup.show {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
  }

  .error-message {
    color: red;
    font-size: 16px;
    margin-top: 10px;
  }

  .text-lg {
    font-size: 20px;
  }
`;

const MAX_SCHOOLS_ERROR_MESSAGE = "You may only select up to 4 schools.";

const FinancingOptionsState = (state: RootState) => state.financingOptions;
const CollegeCostsState = (state: RootState) => state.collegeCosts;

const comparison = (a: FullRideSchool, b: FullRideSchool) => {
  if (a.schoolName < b.schoolName) {
    return -1;
  }
  if (a.schoolName > b.schoolName) {
    return 1;
  }
  return 0;
};

const FullRidePopup: React.FC<CollegeCostsSAIProps> = ({
  hideOverlay,
  fetchNewSchoolData,
}) => {
  const dispatch = useDispatch();

  const [collegeList, setCollegeList] = useState<FullRideSchool[]>([]);
  const [selectedCollegeList, setSelectedCollegeList] = useState<
    FullRideSchool[]
  >([]);
  const [filteredColleges, setFilteredColleges] = useState<string[]>([]);

  const [filterPopupVisible, setFilterPopupVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    selectedIncomeThreshold: "",
    selectedDetailFilter: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const { financingOptions } = useSelector(FinancingOptionsState);
  const { selectedOptions } = useSelector(CollegeCostsState);

  // Newly ticked schools (ids)
  const [newSchools, setNewSchools] = useState<string[]>([]);

  const oldSchools = useMemo(
    () =>
      new Set(
        financingOptions.map((financingOption) => financingOption.collegeId),
      ),
    [financingOptions],
  );

  useEffect(() => {
    // fetch full ride colleges
    fetch(
      `${process.env.REACT_APP_API_URL}/full-ride-colleges?` +
        new URLSearchParams([
          ["familyIncome", financingOptions[0].parentIncome || "0"],
          ["stateOfResidence", selectedOptions.stateOfResidence],
        ]),
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then((response) => response.json())
      .then((data: FullRideSchool[]) => {
        const selectedSchools = data.filter((school) =>
          oldSchools.has(school.collegeId),
        );
        const unselectedSchools = data.filter(
          (school) => !oldSchools.has(school.collegeId),
        );
        setCollegeList(unselectedSchools);
        setSelectedCollegeList(selectedSchools);
        setLoading(false);
      });
  }, [financingOptions, oldSchools, selectedOptions.stateOfResidence]);

  useEffect(() => {
    collegeList.sort(comparison);
    selectedCollegeList.sort(comparison);

    const newSchools = selectedCollegeList
      .filter((school) => !oldSchools.has(school.collegeId))
      .map((school) => school.collegeId);

    setNewSchools(newSchools);

    if (newSchools.length + oldSchools.size > 4) {
      setErrorMessage(MAX_SCHOOLS_ERROR_MESSAGE);
    } else {
      setErrorMessage("");
    }
  }, [collegeList, selectedCollegeList, financingOptions, oldSchools]);

  // search string
  const [searchString, setSearchString] = useState("");

  // search  logic

  useEffect(() => {
    const filteredCollegeList = collegeList
      .filter((school) =>
        school.schoolName.toLowerCase().includes(searchString.toLowerCase()),
      )
      .map((school) => school.collegeId);

    setFilteredColleges(filteredCollegeList);
  }, [collegeList, searchString]);

  const onApply = () => {
    setFilterPopupVisible(false);
    const filteredCollegeList = collegeList
      .filter((school) => {
        switch (selectedFilters.selectedDetailFilter) {
          case "Tuition Only":
            return school.fullRideType === "Tuition";
          case "Tuition and Room & Board":
            return school.fullRideType === "Tuition, Room and Board";
          case "Tuition, Room & Board, and Other Expenses":
            return school.fullRideType === "Tuition, Room and Board, Expenses";
          default:
            return true;
        }
      })
      .map((school) => school.collegeId);

    setFilteredColleges(filteredCollegeList);
  };

  const onAddSchool = () => {
    if (selectedCollegeList.length > 4) {
      setErrorMessage("You can only select up to 4 schools.");
      return;
    }

    fetchNewSchoolData(newSchools);

    newSchools.forEach((school) => {
      dispatch(addNewFinancingOptionBySchoolId(school));
    });

    hideOverlay();
  };

  return (
    <Style>
      <h1 className="title">Which schools are free for you?</h1>
      <div className="message">
        <p>
          Some schools offer <strong>full-ride scholarships</strong> if your
          family makes less than a certain income threshold.
        </p>
        <p>
          Full-ride usually means that you don’t need to pay{" "}
          <strong>tuition</strong> if you attend the school. For some schools,
          full-ride means that you also don’t have to pay for{" "}
          <strong>room & board</strong> or that you also don’t have to pay for{" "}
          <strong>other miscellaneous school expenses.</strong>
        </p>
        <p>
          Based on your income, you may qualify for full-ride at the following
          schools:
        </p>
      </div>

      {loading && <p className="search-result-message">Finding schools...</p>}

      {collegeList.length === 0 && !loading && (
        <p className="search-result-message">
          <b>No schools were found based on your income</b>
        </p>
      )}

      {collegeList.length > 0 && !loading && (
        <>
          <div className="search-bar">
            <SearchBar
              searchString={searchString}
              popupVisible={filterPopupVisible}
              setPopupVisible={setFilterPopupVisible}
              onApply={onApply}
              onChange={(search) => setSearchString(search)}
            />
            <div className={`filter-popup ${filterPopupVisible ? "show" : ""}`}>
              <FullRideFilter
                selectedFilters={selectedFilters}
                onChange={setSelectedFilters}
                onApply={onApply}
              />
            </div>
          </div>
          <div className="selectedSchools"></div>

          <div className="fullRideCollegeListContainer">
            <ul className="collegeList">
              {selectedCollegeList.map((school) => (
                <li key={school.schoolName} className="animated">
                  <FullRideCollegeInformation
                    collegeName={school.schoolName}
                    generalInformation={school.fullRideType}
                    income={school.thresholdAmount}
                    isChecked={true}
                    onClick={(isChecked) => {
                      if (oldSchools.has(school.collegeId)) return;

                      if (!isChecked) {
                        const filteredList = selectedCollegeList.filter(
                          (c) => c.collegeId !== school.collegeId,
                        );

                        setCollegeList([...collegeList, school]);
                        setSelectedCollegeList(filteredList);
                      }
                    }}
                    disabled={oldSchools.has(school.collegeId)}
                  />
                </li>
              ))}
              {selectedCollegeList.length > 0 &&
                selectedCollegeList.length < collegeList.length && (
                  <div className="hr">
                    <hr></hr>
                  </div>
                )}
              {collegeList.map((school) => (
                <li key={school.schoolName} className="animated">
                  {filteredColleges.includes(school.collegeId) && (
                    <FullRideCollegeInformation
                      collegeName={school.schoolName}
                      generalInformation={school.fullRideType}
                      income={school.thresholdAmount}
                      isChecked={false}
                      onClick={(isChecked) => {
                        if (isChecked) {
                          const filteredList = collegeList.filter(
                            (c) => c.collegeId !== school.collegeId,
                          );

                          setSelectedCollegeList([
                            ...selectedCollegeList,
                            school,
                          ]);
                          setCollegeList(filteredList);
                        }
                      }}
                    />
                  )}
                </li>
              ))}
              {filteredColleges.length === 0 &&
                collegeList.length !== 0 &&
                !loading && (
                  <p className="search-result-message">
                    No search results found
                  </p>
                )}
            </ul>
          </div>
          <div className="addButton">
            {newSchools.length > 0 && errorMessage === "" && (
              <Button
                buttonText={`Add ${newSchools.length} new school${
                  newSchools.length > 1 ? "s" : ""
                }`}
                // size="740px"
                fontSize="20px"
                handleOnClick={onAddSchool}
              />
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </>
      )}
    </Style>
  );
};

export default FullRidePopup;

import { FC, ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import theme from "../theme";

interface OverlayProps {
  children: ReactNode;
}

const Style = styled.div`
  position: fixed;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;

  display: flex;
  justify-content: center;
  overflow-y: auto;

  @media (max-width: ${theme.breakpoints.medium}px) {
    padding: 0;
  }
`;

const Overlay: FC<OverlayProps> = ({ children }) => {
  return ReactDOM.createPortal(
    <Style>{children}</Style>,
    document.getElementById("overlay")!,
  );
};

export default Overlay;

import { FC, useEffect, useState } from "react";
import { ResponsiveBar, ResponsiveBarSvgProps } from "@nivo/bar";
import theme from "../theme";
import { moneyFormat } from "../simulations/utils";
import { kFormatter } from "../utils";

let isMobile: boolean;

const GroupedBarGraph: FC<ResponsiveBarSvgProps<any>> = ({ data }) => {
  const [mobile, setMobile] = useState(
    window.innerWidth < theme.breakpoints.xlarge + 1,
  );
  useEffect(() => {
    setMobile(window.innerWidth < theme.breakpoints.xlarge + 1);
  }, [mobile]);
  window.addEventListener("resize", () => {
    setMobile(window.innerWidth < theme.breakpoints.xlarge + 1);
  });
  isMobile = mobile;

  return (
    <ResponsiveBar
      data={data}
      keys={["idr", "standard"]}
      indexBy="income"
      isInteractive={true}
      enableGridY={false}
      groupMode="grouped"
      layout="vertical"
      enableLabel={!isMobile}
      label={(d) => `$${d.value}`}
      labelTextColor="black"
      labelSkipHeight={-1}
      labelSkipWidth={12}
      labelOffset={16}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["opacity", 1]],
      }}
      fill={[{ match: { id: "totalIncome" }, id: "transparent" }]}
      margin={
        isMobile
          ? { top: 60, right: 0, bottom: 50, left: 50 }
          : { top: 60, right: 20, bottom: 50, left: 70 }
      }
      padding={0.3}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickValues: 4,
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Monthly Payment",
        legendPosition: "middle",
        legendOffset: -60,
        format: (v) => `$${moneyFormat(v)}`,
      }}
      colors={[theme.colors.lightGreen, theme.colors.secondary]}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: isMobile ? -50 : 0,
        legendOffset: 36,
        legendPosition: "middle",
        legend: isMobile ? "" : "Annual Salary",
        format: (v) => `$${kFormatter(v)}`,
      }}
      legends={[
        {
          data: [
            {
              id: "idr",
              label: "Income-Driven Repayment Plan",
              color: theme.colors.graphColors.mediumGreen,
            },
            {
              id: "standard",
              label: "Standard Repayment Plan",
              color: theme.colors.secondary,
            },
          ],
          dataFrom: "keys",
          anchor: "top-left",
          direction: isMobile ? "column" : "row",
          justify: false,
          translateX: 0,
          translateY: isMobile ? -50 : -40,
          itemsSpacing: isMobile ? 2 : 180,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Monthly payments under IDR vs Standard Plans"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " for income of: " + e.indexValue
      }
      theme={{
        text: {
          fontSize: 16,
          fontFamily: "Karla",
        },
        legends: { text: { fontSize: 14 } },
        axis: {
          legend: {
            text: {
              fontSize: 14,
            },
          },
          domain: {
            line: {
              stroke: "#777777",
              strokeWidth: 1,
            },
          },
        },
      }}
      tooltip={(data) => {
        const index = data.data;

        return (
          <div
            className={`tooltip-container ${
              window.innerWidth < theme.breakpoints.xlarge
                ? "mobile-tooltip"
                : ""
            }`}
          >
            <p>
              Annual income: ${moneyFormat(index.income ? index.income : 0)}
            </p>
            <p>
              Standard Monthly Repayment:{" "}
              <span className="standard-color">
                ${moneyFormat(index.standard ? index.standard : 0)}
              </span>
            </p>
            <p>
              IDR Monthly Repayment:{" "}
              <span className="idr-color">
                ${moneyFormat(index.idr ? index.idr : 0)}
              </span>
            </p>
          </div>
        );
      }}
    />
  );
};

export default GroupedBarGraph;

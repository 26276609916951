import { Autocomplete, FilterOptionsState, TextField } from "@mui/material";
import theme from "../theme";

interface SearchDropdownProps {
  fieldTitle: string;
  fieldValue: string;
  placeholderValue?: string;
  dropdownOptions: string[];
  handleChange: (e: any, value: any) => void;
  handleInputChange?: (e: any) => void;
  disableFiltering?: boolean;
  freeSolo?: boolean;
  disabled?: boolean;
}

export const SearchDropdown: React.FC<SearchDropdownProps> = ({
  fieldTitle,
  fieldValue,
  dropdownOptions,
  placeholderValue = fieldTitle,
  handleChange,
  handleInputChange = () => {},
  disableFiltering = false,
  freeSolo = false,
  disabled = false,
}) => {
  const reformattedOptions = dropdownOptions.map((dropdownOption) => ({
    label: dropdownOption,
  }));
  const handleFilterOptions = (
    options: { label: string }[],
    state: FilterOptionsState<{ label: string }>,
  ) => {
    let filteredOptions: { label: string }[] = [];

    // deduplicate filtered options
    // TODO: deduplicate initial options in backend instead
    let filteredSet = new Set();
    options.forEach((option) => {
      if (
        !filteredSet.has(option.label) &&
        option.label.toLowerCase().includes(state.inputValue.toLowerCase())
      ) {
        filteredSet.add(option.label);
        filteredOptions.push(option);
      }
    });
    return filteredOptions;
  };
  return (
    // <Style>
    <Autocomplete
      disabled={disabled}
      disablePortal
      filterOptions={disableFiltering ? (x) => x : handleFilterOptions}
      options={reformattedOptions}
      noOptionsText="No results found"
      fullWidth
      freeSolo={freeSolo}
      filterSelectedOptions={false}
      className="search-dropdown"
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => {
        return option.label === value.label;
      }}
      value={{ label: fieldValue }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          sx={{ background: theme.colors.neutrals.white }}
          label={fieldTitle}
          value={fieldValue}
          placeholder={placeholderValue}
          onChange={handleInputChange}
        />
      )}
    />
    // </Style>
  );
};

export default SearchDropdown;

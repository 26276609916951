import styled from "styled-components";
import theme from "../theme";
import { useState } from "react";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { RootState } from "../state";
import { useDispatch, useSelector } from "react-redux";
import US_STATES from "./US_STATES";
import { Controller, useForm } from "react-hook-form";
import {
  currencyRegex,
  integerGreaterThan1,
  invalidInputMessage,
  requiredInputMessage,
} from "../constants";
import { removeCommas } from "../utils";
import SearchDropdown from "../components/SearchDropdown";
import { Divider } from "@mui/material";
import { setShowNetPriceResults } from "./college-costs-slice";
import {
  updateFinancingOptionsBySchoolId,
  setSAIFormValues,
  setCustomSAIValue,
} from "../student-loans/financing-options-slice";

const Style = styled.div`
  .title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .option-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    .option {
      gap: 8px;
    }
  }

  .label {
    width: 80%;
  }

  .help-icon {
    margin-left: 5px;
    width: 0.85rem;
    height: 0.85rem;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .error {
    color: ${theme.colors.graphColors.redOrange};
    font-weight: 700px;
    padding-bottom: 40px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    h3 {
      margin-bottom: 0px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .question-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    form {
      display: flex;
      flex: auto;
      place-content: center;
      align-items: center;
      justify-content: space-evenly;
    }

    .form-control {
      display: flex;
      gap: 0.5rem;
    }

    input[type="radio"] {
      appearance: none;
      margin: 0;
      font: inherit;
      color: ${theme.colors.secondary};
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 50%;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      cursor: pointer;
    }

    input[type="radio"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em ${theme.colors.secondary};
    }

    input[type="radio"]:checked::before {
      transform: scale(1);
    }
  }

  .muiDivider {
    background-color: ${theme.colors.primary};
    width: 80%;
    height: 1.5px;
    border-bottom-width: none;
  }

  .muiDivider-secondary {
    background-color: ${theme.colors.secondary};
    width: 80%;
    height: 1.5px;
    border-bottom-width: none;
  }

  .theme-colors-secondary {
    color: ${theme.colors.secondary};
  }

  .resultText {
    color: ${theme.colors.secondary};
    font-weight: bold;
  }

  .dropdown-container {
    display: flex;

    .dropdown-label {
      flex-basis: 1000px;
      max-width: 50%;
    }
  }

  .sai-boolean-form {
    gap: 1rem;
    justify-content: center;
    flex: none !important;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .dropdown-container {
      flex-direction: column;

      .dropdown-label {
        flex-basis: 0px;
        min-width: 100%;
      }
    }

    .question-container {
      flex-direction: column;
      gap: 1rem;

      .sai-boolean-form {
        justify-content: left;
        gap: 2rem;
      }
    }
  }
`;

const selectCollegeCostsState = (state: RootState) => state.collegeCosts;
const selectedFinancingOptionsState = (state: RootState) =>
  state.financingOptions;

interface IFormInputs {
  stateOfResidency: string;
  householdSize: string;
  parentIncome: string;
  studentIncome: string;
  studentScholarshipsOrGrants: string;
  parentAssets?: string;
  studentAssets?: string;
  SAIOutput?: string;
  PellGrantOutput?: string;
  SAIUserInput?: string;
  PellGrantUserInput?: string;
}

interface CollegeCostsSAIProps {
  showOverlay: boolean;
  hideOverlay: () => void;
  calculateCosts: () => void;
}

const CollegeCostsSAI: React.FC<CollegeCostsSAIProps> = ({ hideOverlay }) => {
  const dispatch = useDispatch();
  const { stateOfResidence } = useSelector(
    selectCollegeCostsState,
  ).selectedOptions;

  const [isSAICalculated, setIsSAICalculated] = useState(false);
  const { results } = useSelector((state: RootState) => state.collegeCosts);
  const { financingOptions } = useSelector(selectedFinancingOptionsState);

  const [userKnowsSAI, setUserKnowsSAI] = useState<boolean>(true);
  const [saiAmount, setSAIAmount] = useState<string>(
    financingOptions[0].studentAidIndexAmount || "0",
  );
  const [pellGrantAmount, setPellGrantAmount] = useState<string>(
    financingOptions[0].pellGrantAmount || "0",
  );
  const saiFormValues = useSelector(
    selectedFinancingOptionsState,
  ).saiFormValues;

  const [SAIInformation, setSAIInformation] = useState({
    stateOfResidency:
      saiFormValues.stateOfResidency === ""
        ? US_STATES[stateOfResidence]
        : saiFormValues.stateOfResidency,
    householdSize: saiFormValues.householdSize,
    parentMaritalStatus: saiFormValues.parentMaritalStatus,
    parentIncome: saiFormValues.parentIncome,
    studentIncome: saiFormValues.studentIncome,
    studentScholarshipsOrGrants: saiFormValues.studentScholarshipsOrGrants,
    parentAssets: saiFormValues.parentAssets,
    studentAssets: saiFormValues.studentAssets,
    SAIOutput: "0",
  });

  // If SAI calculation has been performed
  // const [isSAICalculated, setIsSAICalculated] = useState(false);

  // Handle input change if "No" is selected
  const handleSAIInformationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSAIInformation({
      ...SAIInformation,
      [event.target.name]: event.target.value,
    });
    // When SAI information is changed, SAI calculation must be performed again
  };

  const handleSAICalculate = () => {
    dispatch(setSAIFormValues(SAIInformation));
    fetch(
      `${process.env.REACT_APP_API_URL}/sai-calculation?` +
        new URLSearchParams([
          ["parentAGI", removeCommas(SAIInformation.parentIncome)],
          ["studentAGI", removeCommas(SAIInformation.studentIncome)],
          ["familySize", SAIInformation.householdSize],
          [
            "isSingleParent",
            (SAIInformation.parentMaritalStatus === "Single").toString(),
          ],
          ["parentAssets", removeCommas(SAIInformation.parentAssets)],
          ["studentAssets", removeCommas(SAIInformation.studentAssets)],
          [
            "scholarshipGrant",
            removeCommas(SAIInformation.studentScholarshipsOrGrants),
          ],
          ["olderParentAge", "50"],
        ]),
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then((response) => response.json())
      .then((responseJSON) => {
        dispatch(setShowNetPriceResults(true));
        setSAIAmount(responseJSON.sai);
        setIsSAICalculated(true);
        setPellGrantAmount(responseJSON.pellGrant);
        dispatch(setCustomSAIValue(false));
      });
  };

  const handleSAISubmit = () => {
    results.forEach((result) => {
      dispatch(
        updateFinancingOptionsBySchoolId({
          collegeId: result.collegeId,
          dataToUpdate: {
            studentAidIndexAmount: saiAmount.toString(),
            pellGrantAmount: pellGrantAmount.toString(),
            parentIncome: SAIInformation.parentIncome,
          },
        }),
      );
      if (userKnowsSAI) {
        dispatch(setCustomSAIValue(true));
      }
    });

    dispatch(setShowNetPriceResults(true));
    hideOverlay();
  };

  // Input validation
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      stateOfResidency: SAIInformation.stateOfResidency,
      householdSize: SAIInformation.householdSize,
      parentIncome: SAIInformation.parentIncome,
      studentIncome: SAIInformation.studentIncome,
      studentScholarshipsOrGrants: SAIInformation.studentScholarshipsOrGrants,
      SAIUserInput: "0",
      PellGrantUserInput: "0",
    },
  });

  return (
    <Style>
      <h2 className="title">Student Aid Index Calculator</h2>
      <div className="options">
        <div className="description">
          <p className="label">
            The{" "}
            <a
              className="theme-colors-secondary"
              href="https://studentaid.gov/help-center/answers/article/what-is-sai"
            >
              Student Aid Index (SAI)
            </a>{" "}
            is a calculation of the amount of need-based financial aid that a
            student is eligible to receive.
          </p>
          <p className="label">
            If you don't know your SAl, you can use this tool to calculate it
            and view your personalized net price for each selected school. You
            can also use this tool to calculate your federal{" "}
            <a
              className="theme-colors-secondary"
              href="https://studentaid.gov/understand-aid/types/grants/pell"
            >
              Pell Grant
            </a>{" "}
            eligibility.
          </p>
          <div className="question-container label">
            <h3>Do you know your SAI already?</h3>
            <form className="sai-boolean-form">
              <label className="form-control">
                <input
                  type="radio"
                  value="true"
                  checked={userKnowsSAI === true}
                  onChange={(e) => {
                    setUserKnowsSAI(e.target.value === "true");
                  }}
                />
                Yes
              </label>
              <label className="form-control">
                <input
                  type="radio"
                  value="false"
                  checked={userKnowsSAI === false}
                  onChange={(e) => {
                    setUserKnowsSAI(e.target.value === "true");
                  }}
                />
                No
              </label>
            </form>
          </div>
          <Divider className="muiDivider" variant="middle" />
        </div>
        {!userKnowsSAI ? (
          <form onSubmit={handleSubmit(handleSAICalculate)} className="form">
            <h3 className="label">
              Basic information
              <span className="theme-colors-secondary"> (required)</span>
            </h3>
            <div className="option-container">
              <div className="dropdown-container">
                <p className="dropdown-label">State of Residency</p>
                <SearchDropdown
                  fieldTitle=""
                  disabled={isSAICalculated}
                  placeholderValue="State"
                  fieldValue={SAIInformation.stateOfResidency}
                  dropdownOptions={Object.values(US_STATES)}
                  handleChange={(e, v) => {
                    setSAIInformation({
                      ...SAIInformation,
                      stateOfResidency: v.label,
                    });
                  }}
                />
              </div>

              <div className="dropdown-container">
                <p className="dropdown-label">
                  Parent/Guardian's marital Status
                </p>
                <SearchDropdown
                  fieldTitle=""
                  disabled={isSAICalculated}
                  placeholderValue="Status"
                  fieldValue={SAIInformation.parentMaritalStatus}
                  dropdownOptions={["Single", "Married"]}
                  handleChange={(e, v) => {
                    setSAIInformation({
                      ...SAIInformation,
                      parentMaritalStatus: v.label,
                    });
                  }}
                />
              </div>

              <Controller
                name="householdSize"
                control={control}
                rules={{
                  required: requiredInputMessage,
                  pattern: {
                    value: integerGreaterThan1,
                    message: "*Please enter a number greater than 1",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    label="Size of Household"
                    name="householdSize"
                    value={SAIInformation.householdSize}
                    helperText={errors.householdSize?.message}
                    readOnly={isSAICalculated}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSAIInformationChange(e);
                    }}
                  />
                )}
              />

              <Controller
                name="parentIncome"
                control={control}
                rules={{
                  required: requiredInputMessage,
                  pattern: {
                    value: currencyRegex,
                    message: invalidInputMessage,
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    label="Parent's Income"
                    name="parentIncome"
                    value={SAIInformation.parentIncome}
                    helperText={errors.parentIncome?.message}
                    adornment="$"
                    readOnly={isSAICalculated}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSAIInformationChange(e);
                    }}
                  />
                )}
              />

              <Controller
                name="studentIncome"
                control={control}
                rules={{
                  required: requiredInputMessage,
                  pattern: {
                    value: currencyRegex,
                    message: invalidInputMessage,
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    label="Student's Income"
                    name="studentIncome"
                    value={SAIInformation.studentIncome}
                    helperText={errors.studentIncome?.message}
                    adornment="$"
                    readOnly={isSAICalculated}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSAIInformationChange(e);
                    }}
                  />
                )}
              />

              <Controller
                name="studentScholarshipsOrGrants"
                control={control}
                rules={{
                  required: requiredInputMessage,
                  pattern: {
                    value: currencyRegex,
                    message: invalidInputMessage,
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    label="Student's Scholarships & Grants"
                    name="studentScholarshipsOrGrants"
                    value={SAIInformation.studentScholarshipsOrGrants}
                    helperText={errors.studentScholarshipsOrGrants?.message}
                    adornment="$"
                    readOnly={isSAICalculated}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSAIInformationChange(e);
                    }}
                  />
                )}
              />
            </div>
            <h3 className="label">
              Assets information
              <span style={{ fontWeight: "normal" }}> (optional)</span>
            </h3>
            <div className="option-container">
              <Controller
                name="parentAssets"
                control={control}
                rules={{
                  required: false,
                  pattern: {
                    value: currencyRegex,
                    message: invalidInputMessage,
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    label="Parent's Total Assets"
                    name="parentAssets"
                    value={SAIInformation.parentAssets}
                    helperText={errors.parentAssets?.message}
                    adornment="$"
                    readOnly={isSAICalculated}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSAIInformationChange(e);
                    }}
                  />
                )}
              />
              <Controller
                name="studentAssets"
                control={control}
                rules={{
                  required: false,
                  pattern: {
                    value: currencyRegex,
                    message: invalidInputMessage,
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    label="Student's Total Assets"
                    name="studentAssets"
                    value={SAIInformation.studentAssets}
                    helperText={errors.studentAssets?.message}
                    adornment="$"
                    readOnly={isSAICalculated}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSAIInformationChange(e);
                    }}
                  />
                )}
              />
            </div>

            {isSAICalculated && (
              <div className="option-container resultText">
                <TextInput
                  name="SAIOutput"
                  label="Student Aid Index (SAI)"
                  helperText={errors.studentAssets?.message}
                  value={saiAmount.toString()}
                  readOnly={true}
                  onChange={() => {}}
                />
                <TextInput
                  name="PellGrantOutput"
                  readOnly={true}
                  label="Pell Grant Eligibility"
                  value={pellGrantAmount.toString()}
                  helperText={errors.studentAssets?.message}
                  adornment="$"
                  onChange={() => {}}
                />
              </div>
            )}

            <div className="button-container">
              {isSAICalculated ? (
                <Button buttonText="Submit" handleOnClick={handleSAISubmit} />
              ) : (
                <div className="option">
                  <Button
                    buttonText="Calculate"
                    bgColor={theme.colors.secondary}
                    borderColor={theme.colors.secondary}
                  />
                </div>
              )}
            </div>
          </form>
        ) : (
          <form className="form" onSubmit={handleSubmit(handleSAISubmit)}>
            <div className="option-container">
              <Controller
                name="SAIUserInput"
                control={control}
                rules={{
                  required: requiredInputMessage,
                  pattern: {
                    value:
                      // Regex Pattern for ints from -1500 to 999999
                      /^-?(1500|[1-9][0-9]{0,3}|[1-9][0-9]{4,5}|[1-9][0-9]{0,5}|0)$/,
                    message: invalidInputMessage,
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    name="SAIUserInput"
                    label="Student Aid Index (SAI)"
                    value={saiAmount.toString()}
                    helperText={errors.SAIUserInput?.message}
                    onChange={(e) => {
                      field.onChange(e);
                      setSAIAmount(e.target.value);
                    }}
                  />
                )}
              />
              <Controller
                name="PellGrantUserInput"
                control={control}
                rules={{
                  required: requiredInputMessage,
                  pattern: {
                    value:
                      // Regex Pattern for ints from -1500 to 999999
                      /^-?(1500|[1-9][0-9]{0,3}|[1-9][0-9]{4,5}|[1-9][0-9]{0,5}|0)$/,
                    message: invalidInputMessage,
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    field={field}
                    name="PellGrantUserInput"
                    label="Pell Grant Eligibility"
                    value={pellGrantAmount.toString()}
                    helperText={errors.PellGrantUserInput?.message}
                    adornment="$"
                    onChange={(e) => {
                      field.onChange(e);
                      setPellGrantAmount(e.target.value);
                    }}
                  />
                )}
              />
              <div className="button-container">
                <Button buttonText="Submit" />
              </div>
            </div>
          </form>
        )}
      </div>
    </Style>
  );
};

export default CollegeCostsSAI;

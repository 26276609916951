import { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../state";
import { moneyFormat } from "../../simulations/utils";
import { YearlyLoanAmount } from "../../types";
import HorizontalSimpleBarGraph from "../../components/graphs/HorizontalSimpleBarGraph";
import {
  loanTypeGraphColors,
  loanTypeGraphKeys,
  studentLoanTypeLegendMap,
} from "../../constants";
import theme from "../../theme";
import { LoanFigureComponent } from "../loan-figure-component";

const Style = styled.div`
  .graph {
    width: 90%;
    min-height: 200px;
  }

  @media (max-width: ${theme.breakpoints.xxlarge}px) {
    #accumulative-loan-graph {
      min-height: 200px;
      width: 50vw !important;
    }

    #loan-breakdown-graph {
      min-height: 120px;
      width: 50vw !important;
    }
  }
`;

interface GraphComponentProps {}

export const GraphComponent: FC<GraphComponentProps> = () => {
  const {
    currentYear,
    loanAccumulationStepIndex,
    loanBalanceSoFar,
    currentFinancingOptionsResult,
  } = useSelector((state: RootState) => state.financingOptions);

  const loanBreakdownThisYear =
    currentFinancingOptionsResult &&
    currentFinancingOptionsResult.annualLoanAccumulationData.find(
      (yearLoanObject: YearlyLoanAmount) => yearLoanObject.year === currentYear,
    );

  // calculates width of graph area
  const graphAreaElement = document.getElementById("accumulative-loan-graph");
  const graphAreaWidth = graphAreaElement
    ? graphAreaElement.offsetWidth
    : window.innerWidth;

  return (
    <Style>
      {loanAccumulationStepIndex < 3 ? (
        <LoanFigureComponent
          label="Your total student loan balance"
          value={
            "$" +
            moneyFormat(
              currentFinancingOptionsResult
                ? currentFinancingOptionsResult.totalLoanAmount
                : 0,
            )
          }
          textColor={theme.colors.graphColors.redOrange}
          tooltipText="The estimated amount of student loans you may accumulate over 4 years"
          paddingBottom={24}
        />
      ) : (
        <LoanFigureComponent
          id="loan-accumulation-heading"
          label="Your loan balance so far"
          value={"$" + moneyFormat(loanBalanceSoFar.total)}
          textColor={theme.colors.graphColors.redOrange}
        />
      )}

      {loanAccumulationStepIndex < 3 ? (
        <div className="graph" id="accumulative-loan-graph">
          <HorizontalSimpleBarGraph
            data={[currentFinancingOptionsResult]}
            layout="horizontal"
            xAxisKey="collegeId"
            yAxisKeys={["totalSubsidized", "totalUnsubsidized", "totalPrivate"]}
            showYAxis={true}
            xAxisLabel=""
            yAxisLabel=""
            legendLabelMap={studentLoanTypeLegendMap}
            legendWidth={300}
            graphAreaWidth={graphAreaWidth}
            showLegend={true}
            isInteractive={true}
            colors={[
              theme.colors.graphColors.eggplantPurple,
              theme.colors.lightGreen,
              theme.colors.graphColors.indigo,
            ]}
            barPadding={0.6}
          />
        </div>
      ) : (
        <div className="graph" id="loan-breakdown-graph">
          <HorizontalSimpleBarGraph
            data={[loanBreakdownThisYear]}
            layout="horizontal"
            xAxisKey="collegeId"
            yAxisKeys={loanTypeGraphKeys}
            showYAxis={true}
            xAxisLabel=""
            yAxisLabel=""
            showLegend={false}
            isInteractive={true}
            colors={[theme.colors.graphColors.redOrange].concat(
              loanTypeGraphColors
                .slice(0, (loanAccumulationStepIndex - 3) % 7)
                .concat(Array(7).fill(theme.colors.neutrals.white)),
            )}
            barPadding={0.6}
          />
        </div>
      )}
    </Style>
  );
};

import React from "react";
import styled from "styled-components";
import CheckBox from "./CheckBox";
import MUIToolTip from "./MUIToolTip";

interface FullRideCollegeInformationType {
  collegeName: string;
  income: number;
  generalInformation: string;
  isChecked: boolean;
  disabled?: boolean;
  onClick: (isChecked: boolean) => void;
}

const Style = styled.div`
  .fullRideSchools {
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .universityNameContainer {
    font-weight: bold;
  }

  .universityName {
    margin: 0px;
    font-size: 125%;
  }

  .universityFiliterDetailsText {
    margin: 0px;
    padding-left: 35px;
  }

  .checkBoxContainer {
    padding-top: 7px;
    padding-right: 10px;
  }
  @media (max-width: 768px) {
    .universityFiliterDetailsText {
      font-size: 15px;
    }
  }
  @media (max-width: 480px) {
    .universityFiliterDetailsText {
      font-size: 15px;
    }
  }
`;

const FullRideCollegeInformation: React.FC<FullRideCollegeInformationType> = ({
  collegeName,
  income,
  generalInformation,
  isChecked,
  disabled,
  onClick,
}) => {
  return (
    <Style>
      <div className="fullRideSchools">
        <div className="fullRideDetails">
          <div className="universityNameContainer">
            <CheckBox
              label={collegeName}
              fontWeight={600}
              fontSize="24px"
              checked={isChecked}
              onChange={onClick}
              checkboxBackgroundColor={disabled ? "gray" : undefined}
            />
          </div>
          <div className="universityFiliterDetailsContainer">
            <p className="universityFiliterDetailsText">
              Income &lt; ${income.toLocaleString()} |{" "}
              {generalInformation ===
              "Tuition, Room & Board, and Other Expenses" ? (
                <>
                  <span>{generalInformation}</span>
                  <MUIToolTip
                    text="Other Expenses = all indirect expenses like traveling, books & supplies, transportation, etc."
                    anchorName="Help"
                  />
                </>
              ) : (
                generalInformation
              )}
            </p>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default FullRideCollegeInformation;

import styled from "styled-components";
import theme from "../theme";

const Style = styled.div`
  box-sizing: border-box;
  padding: 50px;
  border: 2px ${theme.colors.secondary} solid;
  border-radius: 0px 0px 10px 10px;

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    padding: 25px;
    margin-bottom: 70px;
    border: none;
  }
`;
const OtherResourcesSummary = () => {
  return (
    <Style>
      <div>
        <p>
          <strong>Visit</strong>{" "}
          <a
            href="https://studentaid.gov/"
            style={{
              textDecoration: "none",
              color: "blue",
            }}
          >
            studentaid.gov
          </a>{" "}
          to learn more about:
        </p>
        <ul>
          <li>Federal aid</li>
          <li>Parent PLUS Loan</li>
          <li>Standard Repayment Plan</li>
          <li>Income Driven Repayment Plan</li>
          <li>Federal loans vs. private loans</li>
        </ul>
        <p>
          <strong>
            Fill out the Free Application for Federal Student Aid (FAFSA)
          </strong>{" "}
          to be eligible for federal aid and loans:{" "}
          <a
            href="https://studentaid.gov/h/apply-for-aid/fafsa"
            style={{
              textDecoration: "none",
              color: "blue",
            }}
          >
            https://studentaid.gov/h/apply-for-aid/fafsa
          </a>
        </p>
      </div>
    </Style>
  );
};

export default OtherResourcesSummary;

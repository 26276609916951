import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

interface CheckBoxProps {
  label: string;
  fontSize?: string;
  fontWeight?: number;
  checkboxBackgroundColor?: string;
  checked?: boolean;
  onChange?: (isChecked: boolean) => void;
}

const Style = styled.div<{
  fontSize?: string;
  fontWeight?: number;
  checkboxBackgroundColor?: string;
}>`
  .label {
    font-family: Karla, sans-serif;
    font-size: ${(props) => props.fontSize || "inherit"};
    font-weight: ${(props) => props.fontWeight || "normal"};
  }

  .MuiCheckbox-root {
    color: ${(props) => props.checkboxBackgroundColor || "#FF8F73"};
    &.Mui-checked {
      color: ${(props) => props.checkboxBackgroundColor || "#FF8F73"};
    }
  }

  @media (max-width: 768px) {
    .label {
      font-size: 20px;
    }
  }
  @media (max-width: 480px) {
    .label {
      font-size: 20px;
    }
  }
`;

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  fontSize,
  fontWeight,
  checkboxBackgroundColor,
  checked,
  onChange,
}) => {
  const handleClick = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <Style
      fontSize={fontSize}
      fontWeight={fontWeight}
      checkboxBackgroundColor={checkboxBackgroundColor}
    >
      <FormGroup onClick={handleClick}>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label={<span className="label">{label}</span>}
          checked={checked}
        />
      </FormGroup>
    </Style>
  );
};

export default CheckBox;

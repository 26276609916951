import { FC, useEffect, useState } from "react";
import {
  BarDatum,
  BarCustomLayerProps,
  LegendLabelDatum,
  ResponsiveBar,
} from "@nivo/bar";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCollegeFinancialSources,
  removeFinancingOptionsBySchoolId,
} from "../../student-loans/financing-options-slice";
import { removeResults } from "../../college-costs/college-costs-slice";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { ReactComponent as Number1 } from "../../assets/icons/number-1.svg";
import { ReactComponent as Number2 } from "../../assets/icons/number-2.svg";
import { ReactComponent as Number3 } from "../../assets/icons/number-3.svg";
import { ReactComponent as Numbe4 } from "../../assets/icons/number-4.svg";
import { moneyFormat } from "../../simulations/utils";
import { GraphProps } from "../../types";
import MUIToolTip from "../MUIToolTip";
import QuestionMark from "../../assets/icons/majesticons_question-circle-line.svg";
import { RootState } from "../../state";

const numbers = [<Number1 />, <Number2 />, <Number3 />, <Numbe4 />];
const labelMargin = 25;
let isMobile: boolean;
const schoolLabelFontSizeMobile = "18px";
const schoolLabelFontSizeFull = "25px";
const stateFontSizeMobile = "18px";
const stateFontSizeFull = "20px";
const totalFontSizeMobile = "18px";
const totalFontSizeFull = "20px";
const netPriceFontSizeMobile = "18px";
const netPriceFontSizeFull = "20px";

const Free = () => {
  return (
    <>
      <g transform={`translate(${isMobile ? 230 : 260}, ${-labelMargin - 14})`}>
        <rect rx="5" ry="5" width="85" height="28" fill="#5CBC6C" />
        <text x="9" y="20" fill="white" fontWeight="bold">
          FREE
        </text>
        <foreignObject x="55" y="2" width="25" height="25">
          <MUIToolTip
            anchorName="tooltip"
            text={
              <>
                {" "}
                Based on the household income you entered, you may
                <br />
                qualify for the <b>full-ride scholarship</b> for this school,{" "}
                <br /> meaning that the school would cover the cost of your{" "}
                <br />
                <b>tuition, room and board, and all indirect expenses</b> <br />
                like traveling, books & supplies, transportation, etc.
              </>
            }
            img={QuestionMark}
          />
        </foreignObject>
      </g>
    </>
  );
};

const AlmostFree = () => {
  return (
    <>
      <g transform={`translate(${isMobile ? 230 : 300}, ${-labelMargin - 14})`}>
        <rect rx="5" ry="5" width="160" height="28" fill="#5CBC6C" />
        <text x="9" y="20" fill="white" fontWeight="bold">
          ALMOST FREE
        </text>

        <foreignObject x="130" y="2" width="25" height="25">
          <MUIToolTip
            anchorName="tooltip"
            text={
              <>
                Based on the household income you entered, you may qualify for{" "}
                <br />
                the <b>full-ride scholarship</b> for this school. In this case,
                the school
                <br /> would cover the cost of your <b>tuition</b>. However, the
                school may or <br /> may not cover room and board expenses, and
                it would not cover
                <br /> indirect expenses like traveling, books & supplies,
                transportation, <br />
                etc.
              </>
            }
            img={QuestionMark}
          />
        </foreignObject>
      </g>
    </>
  );
};

const TotalLabels: FC<BarCustomLayerProps<BarDatum>> = ({
  bars,
  xScale,
  legendData,
}) => {
  const showNetPriceResults = useSelector(
    (state: RootState) => state.collegeCosts.showNetPriceResults,
  );
  const customSAIValue = useSelector(
    (state: RootState) => state.financingOptions.customSAIValue,
  );

  return (
    <>
      {bars.map(({ data: { data, indexValue }, y, width }, i) => {
        const [BarLegendProps] = legendData;
        const legendList = BarLegendProps[1];
        const total = legendList?.reduce((result, item) => {
          const value = Number(data[item.id]);
          if (!isNaN(value)) {
            result += item.hidden ? 0 : value;
          }
          return result;
        }, 0);

        return (
          <g
            transform={`translate(${xScale(labelMargin)}, ${y})`}
            key={`${indexValue}-${i}`}
          >
            <text
              className="bar-total-label"
              x={0}
              y={-labelMargin}
              textAnchor="left"
              alignmentBaseline="central"
              style={{
                fill: theme.colors.neutrals.mediumGray,
                fontSize: isMobile ? totalFontSizeMobile : totalFontSizeFull,
                textDecoration: "line-through",
              }}
            >
              {`$${moneyFormat(total)}`}
            </text>

            <text
              className="bar-total-label"
              x={110}
              y={-labelMargin}
              textAnchor="left"
              alignmentBaseline="central"
              style={{
                fill: theme.colors.primary,
                fontSize: isMobile
                  ? netPriceFontSizeMobile
                  : netPriceFontSizeFull,
                fontWeight: "bold",
              }}
            >
              {`Net price: $${moneyFormat(
                Number(
                  showNetPriceResults
                    ? data.annualNetPrice || 0
                    : data.averageAnnualNetPrice || 0,
                ),
              )}`}
            </text>
            {showNetPriceResults &&
              !isMobile &&
              !customSAIValue &&
              data.fullRideStatus === "FREE" && <Free />}
            {showNetPriceResults &&
              !isMobile &&
              !customSAIValue &&
              data.fullRideStatus === "ALMOST_FREE" && <AlmostFree />}
          </g>
        );
      })}
    </>
  );
};

const Delete: FC<BarCustomLayerProps<BarDatum>> = ({ bars }) => {
  const dispatch = useDispatch();
  let maxWidth = 0;
  bars.map(({ data: { data, indexValue }, x, y, width, height }, i) => {
    if (maxWidth < x + width) {
      maxWidth = x + width;
    }
    return 0;
  });
  return (
    <>
      {bars.map(({ data: { data, indexValue }, x, y, width, height }, i) => {
        return (
          <g
            transform={`translate(${maxWidth + (isMobile ? 25 : 50)}, ${
              y + height / 2 - labelMargin / 2
            })`}
            key={`${indexValue}-${i}`}
            onClick={() => {
              dispatch(removeCollegeFinancialSources(data.collegeId));
              dispatch(removeResults(data.collegeId));
              dispatch(removeFinancingOptionsBySchoolId(data.collegeId));
            }}
            style={{ cursor: "pointer" }}
          >
            <DeleteIcon />
          </g>
        );
      })}
    </>
  );
};

// This is a utility function to split a string into lines
function splitIntoLines(
  text: string,
  maxLineWidth: number,
  getTextWidth: { (text: any): number; (arg0: string): any },
) {
  const words = text.split(/[\s-]+/);
  let line = "";
  let lines = [];
  words.forEach((word) => {
    const testLine = line + " " + word;
    const lineWidth = getTextWidth(testLine);
    if (lineWidth > maxLineWidth) {
      lines.push(line);
      line = word;
    } else {
      line = testLine;
    }
  });
  lines.push(line);
  return lines;
}

const getTextWidth = (text: string, fontSize: number) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context == null) return 0;
  context.font = `${fontSize}px sans-serif`;
  const metrics = context.measureText(text);
  return metrics.width;
};

const NumberLabel: FC<BarCustomLayerProps<BarDatum>> = ({ bars }) => {
  return (
    <>
      {bars.map(({ data: { data, indexValue }, x, y, width, height }, i) => {
        return (
          <g
            transform={`translate(${isMobile ? -50 : -440}, ${
              y - labelMargin - 15 + (isMobile ? -48 : 0)
            }) scale(${isMobile ? 0.8 : 1.2})`}
            key={`${indexValue}-0${i}`}
          >
            {numbers[bars.length - 1 - i]}
          </g>
        );
      })}
    </>
  );
};
const SchoolLabels: FC<BarCustomLayerProps<BarDatum>> = ({ bars }) => {
  const showNetPriceResults = useSelector(
    (state: RootState) => state.collegeCosts.showNetPriceResults,
  );
  return (
    <>
      {bars.map(({ data: { data, indexValue }, x, y, width, height }, i) => {
        const asterisk =
          !data.coefficientAvailable && data.fullRideStatus === "NOT_FREE"
            ? "**"
            : "";
        const lines = splitIntoLines(
          data.schoolName.toString() + (showNetPriceResults ? asterisk : ""),
          230,
          (text: string) => getTextWidth(text, isMobile ? 15 : 18),
        );
        const calculateSchoolNameDy = (index: number, linesLength: number) => {
          if (index === 0) {
            return linesLength > 1 ? 0 : 6;
          } else {
            if (isMobile) {
              return linesLength > 1 ? 15 * 1.2 : 15 * 1.2 + 6;
            } else {
              return linesLength > 1 ? 20 * 1.2 : 20 * 1.2 + 6;
            }
          }
        };

        const calcualteStateDy = (linesLength: number) => {
          if (isMobile) {
            return linesLength > 1 ? (linesLength - 1) * 12 : 5;
          } else {
            return linesLength > 1 ? (linesLength - 1) * 30 : 12;
          }
        };
        return (
          <g
            transform={`translate(${isMobile ? 0 : -350}, ${
              y + labelMargin / 2 + (isMobile ? -65 : 0)
            }) 
            scale(${isMobile ? 1 : 1})`}
            key={`${indexValue}-0${i}`}
          >
            <text
              className="bar-total-label"
              x={0}
              y={-labelMargin}
              textAnchor="left"
              alignmentBaseline="central"
            >
              {lines.map((line, i) => (
                <tspan
                  x={0}
                  dy={calculateSchoolNameDy(i, lines.length)}
                  style={{
                    fill: "rgb(51, 51, 51)",
                    fontSize: isMobile
                      ? schoolLabelFontSizeMobile
                      : schoolLabelFontSizeFull,
                    fontWeight: "bold",
                  }}
                >
                  {line}
                </tspan>
              ))}
            </text>
            <br />
            <text>
              <tspan
                dy={calcualteStateDy(lines.length)}
                style={{
                  fontWeight: "lighter",
                  fontSize: isMobile ? stateFontSizeMobile : stateFontSizeFull,
                }}
              >
                {data.publicSchool
                  ? data.inState
                    ? "In-State"
                    : "Out-of-State"
                  : "Private"}
              </tspan>
            </text>
          </g>
        );
      })}
    </>
  );
};

const NetPriceBarGraph: FC<GraphProps> = ({
  data,
  colors = [theme.colors.primary],
  layout,
  legendLabelMap,
  xAxisKey,
  yAxisKeys,
  xAxisLabel,
  legendWidth = 250,
  showLegend = true,
  enableLabel = false,
}) => {
  const [mobile, setMobile] = useState(
    window.innerWidth < theme.breakpoints.xlarge + 1,
  );
  useEffect(() => {
    setMobile(window.innerWidth < theme.breakpoints.xlarge + 1);
  }, [mobile]);
  window.addEventListener("resize", () => {
    setMobile(window.innerWidth < theme.breakpoints.xlarge + 1);
  });
  isMobile = mobile;
  return (
    <ResponsiveBar
      valueFormat=" ^-$,"
      data={data}
      legendLabel={(x: LegendLabelDatum<BarDatum>) => {
        return `${legendLabelMap ? legendLabelMap[x.id] : x.id}`;
      }}
      tooltipLabel={(input) => {
        return legendLabelMap ? legendLabelMap[input.id] : input.id.toString();
      }}
      label={xAxisLabel}
      keys={yAxisKeys}
      layout={layout}
      labelSkipHeight={100}
      indexBy={xAxisKey}
      margin={{
        top: 30,
        right: isMobile ? 65 : 100,
        bottom: 100,
        //the space to save for name of the college
        left: isMobile ? 50 : 450,
      }}
      padding={isMobile ? 0.9 : 0.7}
      enableGridY={false}
      enableGridX={false}
      valueScale={{ type: "linear" }}
      colors={colors}
      animate={true}
      enableLabel={enableLabel}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      theme={{
        text: {
          fontSize: 18,
          fontFamily: "Karla",
        },
        legends: { text: { fontSize: 13 } },
        axis: {
          ticks: {
            text: {
              fontSize: 20,
              fill: "#000000",
              fontWeight: "bolder",
            },
          },
        },
      }}
      axisBottom={null}
      legends={
        showLegend
          ? [
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: isMobile ? 0 : -400,
                translateY: -30,
                itemWidth: legendWidth || 250,
                itemHeight: 20,
                itemsSpacing: 1,
                symbolSize: 16,
                itemDirection: "left-to-right",
                toggleSerie: true,
              },
            ]
          : []
      }
      layers={[
        "axes",
        "grid",
        "bars",
        "markers",
        TotalLabels,
        "legends",
        SchoolLabels,
        Delete,
        NumberLabel,
      ]}
    />
  );
};

export default NetPriceBarGraph;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../state";
import { fetchCollegeCostResults, setShowResults } from "./college-costs-slice";
import { CollegeCostsInputs } from "./CollegeCostsInputs";
import CollegeCostsResults from "./CollegeCostsResults";
import Popup from "../components/Popup";
import { PopupType, PopupTypes } from "../types";
import CollegeCostsSAI from "./SAIPopup";
import CollegeCostsParentPlus from "./CollegeCostsParentPlus";
import FullRidePopup from "./FullRidePopup";
import { addActivePage } from "../home/navigation-slice";
import Overlay from "../components/Overlay";

const Style = styled.div`
  .button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .college-costs-graphs {
    width: 100%;
  }
`;

const selectCollegeCostsState = (state: RootState) => state.collegeCosts;

export const CollegeCostsPage = () => {
  const dispatch = useDispatch();
  const { activePages } = useSelector((state: RootState) => state.navigation);
  const {
    selectedOptions,
    results,
    showNetPriceResults,
    showStickerPriceResults,
  } = useSelector(selectCollegeCostsState);
  const [showOverlay, setShowOverlay] = useState(false);
  const [popup, setPopup] = useState<PopupType>();

  const hideOverlay = () => {
    setShowOverlay(false);
    document.body.classList.remove("noscroll");
  };

  const displayOverlay = (popupType?: PopupType) => {
    if (popupType) {
      setPopup(popupType);
    }

    setShowOverlay(true);
    document.body.classList.add("noscroll");
  };

  useEffect(() => {
    if (!activePages.includes("COLLEGE_COSTS") && results.length > 0) {
      dispatch(addActivePage("COLLEGE_COSTS"));
    }
  }, [activePages, showNetPriceResults, results.length, dispatch]);

  const handleCalculateCollegeCosts = () => {
    if (!selectedOptions.college) return;
    const newCollegeId = selectedOptions.college.collegeId;
    fetchNewSchoolData(newCollegeId);
  };
  const fetchNewSchoolData = (collegeId: string | string[]) => {
    const collegeIds = results.map((res) => res.collegeId);
    if (typeof collegeId === "string") {
      collegeIds.push(collegeId);
    } else {
      collegeIds.push(...collegeId);
    }

    let params = new URLSearchParams([
      ["stateOfResidence", selectedOptions.stateOfResidence],
    ]);

    collegeIds.map((id) => params.append("collegeIds", id.toString()));
    fetch(`${process.env.REACT_APP_API_URL}/college-cost?` + params, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJSON) => {
        dispatch(fetchCollegeCostResults(responseJSON));
        dispatch(setShowResults(true));
      });
  };

  return (
    <Style>
      {showOverlay && (
        <Overlay>
          {popup === PopupTypes.ADD_SCHOOL && (
            <Popup
              hideOverlay={hideOverlay}
              children={
                <CollegeCostsInputs
                  showOverlay={showOverlay}
                  hideOverlay={hideOverlay}
                  calculateCosts={() => handleCalculateCollegeCosts()}
                />
              }
            />
          )}

          {popup === PopupTypes.EDIT_SAI && (
            <Popup
              hideOverlay={hideOverlay}
              children={
                <CollegeCostsSAI
                  showOverlay={showOverlay}
                  hideOverlay={hideOverlay}
                  calculateCosts={handleCalculateCollegeCosts}
                />
              }
            />
          )}
          {popup === PopupTypes.ParentPlus_Eligibility && (
            <Popup
              hideOverlay={hideOverlay}
              children={
                <CollegeCostsParentPlus
                  showOverlay={showOverlay}
                  hideOverlay={hideOverlay}
                />
              }
            />
          )}
          {popup === PopupTypes.FULLRIDE_VIEW && (
            <Popup
              hideOverlay={hideOverlay}
              children={
                <FullRidePopup
                  showOverlay={showOverlay}
                  hideOverlay={hideOverlay}
                  fetchNewSchoolData={fetchNewSchoolData}
                />
              }
            />
          )}
          {popup === PopupTypes.Mobile_Average && (
            <Popup
              hideOverlay={hideOverlay}
              children={
                <div>
                  <p>
                    Finiverse uses the average amount of institutional aid,
                    federal aid, and state & local aid from the IPEDS dataset to
                    estimate your net price for each school.
                  </p>
                </div>
              }
            />
          )}
        </Overlay>
      )}
      {showStickerPriceResults && results.length > 0 ? (
        <div className="">
          <CollegeCostsResults results={results} showOverlay={displayOverlay} />
        </div>
      ) : (
        <div className="input-background">
          <CollegeCostsInputs
            showOverlay={showOverlay}
            hideOverlay={hideOverlay}
            calculateCosts={() => handleCalculateCollegeCosts()}
          />
        </div>
      )}
    </Style>
  );
};

export default CollegeCostsPage;

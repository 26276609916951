import InputAdornment from "@mui/material/InputAdornment";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "../theme";
import { ControllerRenderProps } from "react-hook-form";
import styled from "styled-components";

interface TextInputProps {
  label?: string;
  adornment?: string;
  value?: string;
  name?: string;
  helperText?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  color?: string;
  bgColor?: string;
  width?: string | number;
  fullWidth?: boolean;
  field?: ControllerRenderProps<any, any>;
  errorMessage?: string;
}

const Style = styled.div`
  .option {
    .label {
      min-width: 50%;
    }
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    width: 200px !important;
    .option {
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: flex-start !important;

      .label {
        margin-bottom: 0px;
        min-width: 100%;
      }
    }
  }
`;

const TextFieldStyle = styled.div`
  .error-message {
    font-size: 15px !important;
  }

  .numberTextField {
    width: 100%;
  }
`;

const customTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.secondary,
    },
  },
});

const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  adornment,
  value,
  onChange,
  readOnly = false,
  color = theme.colors.neutrals.gray,
  bgColor = theme.colors.veryLightOrange,
  width = window.innerWidth < theme.breakpoints.medium
    ? window.innerWidth < theme.breakpoints.small
      ? "100%"
      : "8rem"
    : "10rem",
  fullWidth = false,
  field = {},
  helperText,
  errorMessage,
}) => {
  // MUI props for the input
  const inputProps: Partial<OutlinedInputProps> = {
    readOnly: readOnly,
    startAdornment: adornment && (
      <InputAdornment position="start">{adornment}</InputAdornment>
    ),
  };

  return (
    <Style>
      <div className="option">
        {label && <p className="label">{label}</p>}
        <ThemeProvider theme={customTheme}>
          <TextFieldStyle>
            <TextField
              {...field}
              className="numberTextField"
              size="small"
              variant="outlined"
              value={value}
              name={name}
              fullWidth={fullWidth}
              InputProps={inputProps}
              helperText={helperText}
              // custom styles for the input by editing MUI css classes
              sx={{
                marginTop: "1em",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: color,
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: readOnly
                    ? theme.colors.neutrals.lightGray
                    : bgColor,
                  width: fullWidth ? "100%" : width,
                  borderRadius: "10px",
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: color,
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: color,
                  },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                  boxSizing: "border-box",
                  paddingX: "0.5em",
                  paddingTop: "0.25em",
                  width: fullWidth ? "100%" : width,
                },
              }}
              onChange={onChange}
            />
            <div className="error-container">
              <p className="error-message">{errorMessage}</p>
            </div>
          </TextFieldStyle>
        </ThemeProvider>
      </div>
    </Style>
  );
};

export default TextInput;

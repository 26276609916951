import styled from "styled-components";
import theme from "../theme";
import { FC } from "react";

const Style = styled.div`
  font-size: 18px;
  background: ${theme.colors.lightOrange};
  border: 2px solid ${theme.colors.lightOrange};
  padding: 24px 50px;
  border-radius: 6px;
  text-align: left;
  margin: 50px 100px;
  line-height: 150%;

  @media print {
    margin: 0 0 12px 0;
  }

  .sub-title {
    color: ${theme.colors.primary};
    margin-bottom: 0;
    font-weight: 700;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    font-size: 15px;
    padding: 25px;
    margin: 25px;
  }
`;
const SummaryHeader: FC = () => {
  return (
    <Style>
      <p className="summary-text">
        This report summarizes the essential information, numbers, and results
        of Finiverse in a printable and shareable format.
      </p>
      <div className="sub-title">What’s included:</div>
      <ul>
        <li>Breakdowns of college costs by school</li>
        <li>Breakdowns of loans and repayment plans by school</li>
        <li>Average starting salary of your selected job </li>
        {/*<li>*/}
        {/*  Estimated net assets 20 years after graduating college based on our*/}
        {/*  risk simulation.*/}
        {/*</li>*/}
      </ul>
      <p className="summary-text">
        Keep a copy for your own recordkeeping or share it with parents and
        counselors.
      </p>
    </Style>
  );
};

export default SummaryHeader;

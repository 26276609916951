import * as d3 from "d3";
import {
  LoanPlan,
  PersonalExpensesOverTime,
  ResultsSummaryInputs,
  SingleSimulationResults,
  XYPair,
} from "../types";
import { curveCatmullRom, line } from "d3";
import { Fragment } from "react";

let formatter = Intl.NumberFormat("en", {
  notation: "compact",
  minimumFractionDigits: 1,
  trailingZeroDisplay: "stripIfInteger",
} as Intl.NumberFormatOptions);

export const collegeEventContent = (selectedInputs: ResultsSummaryInputs) => {
  return {
    message: (
      <>
        Studied <b>{selectedInputs.school.major || "Computer Science"}</b> at{" "}
        <b>
          {selectedInputs.school.college
            ? selectedInputs.school.college.name
            : "University of Pennsylvania"}
        </b>
      </>
    ),
    color: "green",
  };
};

export const graduationEventContent = (results: SingleSimulationResults) => {
  return {
    message: (
      <>
        {results.collegePeriod.graduated ? "Graduated in" : "Dropped out after"}{" "}
        <b>{results.collegePeriod.yearsInSchool}</b> years
      </>
    ),
    color: results.collegePeriod.graduated ? "green" : "orange",
  };
};

export const jobEventContent = (
  selectedInputs: ResultsSummaryInputs,
  results: SingleSimulationResults,
) => {
  return [
    {
      message: (
        <>
          Worked at a job related to{" "}
          <b>{selectedInputs.school.major || "Degree"}</b> for{" "}
          <b>{results.jobPeriod.yearsEmployedInJobRelatedToDegree}</b> years
        </>
      ),
      color: "green",
    },
    {
      message: (
        <>
          Worked at a job related to{" "}
          <b>{selectedInputs.school.major || "Degree"}</b> for{" "}
          <b>{results.jobPeriod.yearsEmployedInJobRelatedToDegree}</b> years
        </>
      ),
      color: "green",
    },
    {
      message: (
        <>
          Were unemployed for <b>{results.jobPeriod.yearsUnemployed}</b> years
        </>
      ),
      color: "orange",
    },
  ];
};

export const timelineEventContent = (
  selectedInputs: ResultsSummaryInputs,
  results: SingleSimulationResults,
) => {
  const finalTimeline = [
    collegeEventContent(selectedInputs),
    graduationEventContent(results),
  ].concat(jobEventContent(selectedInputs, results));
  console.log(finalTimeline);
  return finalTimeline;
};

//handle commas every three 0s
export const moneyFormat = (num: number) => {
  const text = num.toString();
  let res = "";
  let counter = 0;
  for (let i = text.length - 1; i >= 0; i--) {
    counter++;
    res = text[i] + res;
    if (counter % 3 === 0 && i !== 0) {
      res = "," + res;
    }
  }
  return res;
};

// Calculate bins for total dataset
export const calculateBins = (min: number, max: number, numOfBins: number) => {
  const histGenerator = d3
    .bin()
    .domain([min, max]) // Set the domain to cover the entire intervall [0;]
    .thresholds(numOfBins - 1); // number of thresholds; this will create 19+1 bins

  return histGenerator;
};

// Put data into calculated bins
export const binData = (
  histGenerator: d3.HistogramGeneratorNumber<number, number>,
  dataToBin: number[],
) => {
  const binnedData = histGenerator(dataToBin);

  return binnedData;
};

// Calculate likelihood for each simulation's result
export const formatHistogramData = (
  numberOfSimulations: number,
  dataToFormat: d3.Bin<number, number>[],
) => {
  const formattedData = dataToFormat.map((bin) => {
    console.log(bin);
    return {
      bin: bin,
      amount: `${
        bin.x0 && bin.x1
          ? `${formatter.format(bin.x0)} - ${formatter.format(bin.x1)}`
          : ""
      }`,
      likelihood: bin.length / numberOfSimulations,
    };
  });

  return formattedData;
};

// Return complete formatted histogram data for selected metric
export const convertAndFormatHistogramData = (
  metricData: number[],
  simulationNumber: number,
) => {
  const histGenerator = calculateBins(
    Math.min(...metricData),
    Math.max(...metricData),
    10,
  );

  const binnedData = binData(
    histGenerator,
    metricData.slice(0, simulationNumber),
  );

  const formattedData = formatHistogramData(simulationNumber, binnedData);

  return formattedData;
};

//Round to the nearest 100
export const roundToNearestHundred = (num: number) => {
  const roundedNumber = Math.round(num / 100) * 100;

  return roundedNumber;
};

// Graph helper functions
export const getPersonalExpensesOverTime = (
  results: SingleSimulationResults,
  loanPlan: LoanPlan,
) => {
  return loanPlan === LoanPlan.Standard
    ? results.summaryStats.personalExpensesOverTime
    : results.summaryStats.personalIDRExpensesOverTime;
};

export const getTotalEarningsOverTime = (results: SingleSimulationResults) => {
  return results.summaryStats.totalEarningsOverTime.map(({ x, y }) => {
    return { x: x * 4, y: roundToNearestHundred(y) };
  });
};

export const getTotalLoanExpensesOverTime = (
  results: SingleSimulationResults,
  loanPlan: LoanPlan,
) => {
  const data =
    loanPlan === LoanPlan.Standard
      ? results.summaryStats.personalExpensesOverTime
      : results.summaryStats.personalIDRExpensesOverTime;

  return data.map(({ annualStudentLoanPayment }, index) => {
    return { x: index, y: roundToNearestHundred(annualStudentLoanPayment) };
  });
};

export const getTotalExpensesOverTime = (
  results: SingleSimulationResults,
  loanPlan: LoanPlan,
  personalExpensesOverTime: PersonalExpensesOverTime[],
) => {
  const data =
    loanPlan === LoanPlan.Standard
      ? results.summaryStats.personalExpensesOverTime
      : results.summaryStats.personalIDRExpensesOverTime;

  return data.map(
    ({ annualTaxes, annualStudentLoanPayment, annualOtherExpenses }, index) => {
      const adjustedExpenses =
        annualTaxes + annualStudentLoanPayment + annualOtherExpenses;

      return {
        x: index,
        y: roundToNearestHundred(adjustedExpenses),
      };
    },
  );
};

export const getMilestonesOverTime = (results: SingleSimulationResults) => {
  return results.summaryStats.milestonesOverTime.map(({ x, milestone }) => {
    return { x: x * 4, milestone: milestone };
  });
};

export const mapPersonalExpensesToGraphData = (
  personalExpensesOverTime: PersonalExpensesOverTime[],
  totalEarningsOverTime: XYPair[],
  totalLoanExpensesOverTime: XYPair[],
) => {
  return personalExpensesOverTime.map((expense, index) => ({
    year: index + 1,
    totalIncome: roundToNearestHundred(totalEarningsOverTime[index].y),
    taxes: roundToNearestHundred(expense.annualTaxes),
    studentLoanPayments: roundToNearestHundred(
      totalLoanExpensesOverTime[index].y,
    ),
    other: roundToNearestHundred(expense.annualOtherExpenses),
  }));
};

export const getAnnualIncomePoints = (
  results: SingleSimulationResults,
  loanPlan: LoanPlan,
  totalEarningsOverTime: XYPair[],
  personalExpensesOverTime: PersonalExpensesOverTime[],
) => {
  const data =
    loanPlan === LoanPlan.Standard
      ? results.summaryStats.personalExpensesOverTime
      : results.summaryStats.personalIDRExpensesOverTime;

  return data.map(({ annualSalary }, index) => {
    return {
      x: index,
      y: annualSalary,
    };
  });
};

export const getAnnualExpensesPoints = (
  results: SingleSimulationResults,
  loanPlan: LoanPlan,
  personalExpensesOverTime: PersonalExpensesOverTime[],
) => {
  const data =
    loanPlan === LoanPlan.Standard
      ? results.summaryStats.personalExpensesOverTime
      : results.summaryStats.personalIDRExpensesOverTime;

  return data.map(
    ({ annualTaxes, annualStudentLoanPayment, annualOtherExpenses }, index) => {
      return {
        x: index,
        y: annualTaxes + annualStudentLoanPayment + annualOtherExpenses,
      };
    },
  );
};

export const getAnnualStudentLoanPoints = (
  results: SingleSimulationResults,
  loanPlan: LoanPlan,
) => {
  const data =
    loanPlan === LoanPlan.Standard
      ? results.summaryStats.personalExpensesOverTime
      : results.summaryStats.personalIDRExpensesOverTime;

  return data.map(({ annualStudentLoanPayment }, index) => {
    return {
      x: index,
      y: annualStudentLoanPayment,
    };
  });
};

// Returns a component that renders a line for the SimulationCombinedGraph (e.g. AnnualIncome, AnnualExpenses)
export const createAnnualLineComponent = (
  dataKey: string,
  dataPoints: any[],
  color: string,
) => {
  const AnnualLine = ({ bars, xScale, yScale }: any) => {
    const filteredBars = bars.filter((bar: any) => bar.data.id === dataKey);

    const lineGenerator = line()
      .x(
        (bar: any) =>
          xScale(bar.data.data.year <= 20 ? bar.data.data.year : null) +
          bar.width / 2,
      )
      .y((bar: any) => yScale(dataPoints[bar.data.data.year - 1]?.y))
      .curve(curveCatmullRom.alpha(0.25));

    return (
      <Fragment>
        {/* Render the path */}
        <path
          d={lineGenerator(filteredBars) || ""}
          fill="none"
          stroke={color}
          strokeWidth={3}
          style={{ pointerEvents: "none" }}
        />

        {/* Render the circles */}
        {bars.map((bar: any) => (
          <circle
            key={bar.key}
            cx={xScale(bar.data.data.year) + bar.width / 2}
            cy={yScale(dataPoints[bar.data.data.year - 1]?.y)}
            r={4}
            fill={color}
            style={{ pointerEvents: "none" }}
          />
        ))}
      </Fragment>
    );
  };

  return AnnualLine;
};

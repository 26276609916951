import { FC, ReactNode } from "react";

import styled from "styled-components";
import theme from "../theme";

const Style = styled.div`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .card-title {
    color: white;
    background-color: ${theme.colors.secondary};
    font: ${theme.fonts.body};
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
  }

    @media print {
      padding: 4% 2%;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    .card-title {
      border-radius: 0px;
      padding: 10px;
    }
  }
`;

interface SummaryCardProps {
  title: string;
  children: ReactNode;
}

const SummaryCard: FC<SummaryCardProps> = ({ title, children }) => {
  return (
    <Style>
      <div className="card-title">{title}</div>
      <div className="card-child">{children}</div>
    </Style>
  );
};

export default SummaryCard;

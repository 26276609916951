import styled from "styled-components";
import Button from "./Button";
import RadioButtonGroup from "./RadioButtonGroup";

const Style = styled.div`
  .filterContainer {
    padding: 5px;
    align-items: center;
    margin: 0 auto;
    overflow-x: hidden;
    max-height: 400px;
  }

  .filterPopUpTitle {
    font-weight: bold;
    font-size: 30px;
    padding-bottom: 10px;
  }

  .filterInformationDetails,
  .incomeDetail {
    font-size: 12px;
    padding-bottom: 20px;
  }

  .filterInformationTitle,
  .incomeTitle {
    font-weight: bold;
    font-size: 20px;
  }

  .filterOptionsContainer {
    display: flex;
    flex-direction: column;
    font-family: Karla, sans-serif;
  }

  .filterOptions {
    display: flex;
  }

  .bottomButtons {
    display: flex;
    margin-top: 30px;
  }

  .clearAll {
    padding-right: 50px;
    font-weight: bold;
    font-size: 20px;
    color: #ff8f73;
  }

  .clearAllContainer {
    display: flex;
    align-items: center;
  }

  .questionMarkIcon {
    padding-top: 10px;
  }

  .applyButtonContainer {
    padding-left: 10%;
  }

  .filterIncomeContainer {
    margin-bottom: 30px;
  }

  .filter-wrap {
    border: 2px solid #547cff;
    border-radius: 10px;
    padding: 30px;
    margin: 0 auto;
    max-height: 400px;
  }

  @media (max-width: 768px) {
    .filterPopUpTitle {
      font-size: 24px;
      padding-bottom: 8px;
    }

    .filterInformationTitle,
    .incomeTitle {
      font-size: 18px;
    }

    .filterInformationDetails,
    .incomeDetail {
      font-size: 10px;
      padding-bottom: 15px;
    }

    .clearAll {
      font-size: 11px;
    }
  }

  @media (max-width: 480px) {
    .filterPopUpTitle {
      font-size: 20px;
      padding-bottom: 6px;
    }

    .filterInformationTitle,
    .incomeTitle {
      font-size: 16px;
    }

    .filterInformationDetails,
    .incomeDetail {
      font-size: 10px;
      padding-bottom: 10px;
    }
  }
`;

const label1 = "Tuition Only";
const label2 = "Tuition and Room & Board";
const label3 = "Tuition, Room & Board, and Other Expenses";

interface FullRideFilterProps {
  selectedFilters: {
    selectedIncomeThreshold: string;
    selectedDetailFilter: string;
  };
  onChange: (selectedFilters: {
    selectedIncomeThreshold: string;
    selectedDetailFilter: string;
  }) => void;
  onApply: () => void;
}

const FullRideFilter = ({
  selectedFilters,
  onChange,
  onApply,
}: FullRideFilterProps) => {
  const clearAllFilters = () => {
    onChange({
      selectedIncomeThreshold: "",
      selectedDetailFilter: "",
    });
  };

  return (
    <Style>
      <div className="filter-wrap">
        <div className="filterContainer">
          <p className="filterPopUpTitle">Filter schools by:</p>

          <div className="filterInformationContainer">
            <p className="filterInformationTitle">
              Type of expenses covered by school
            </p>
            <p className="filterInformationDetails">
              The type of expenses that would be covered by a full-ride
              qualification is different from school to school.
            </p>
          </div>
          <div className="filterOptionsContainer">
            <RadioButtonGroup
              buttonOptions={[label1, label2, label3]}
              fieldValue={selectedFilters.selectedDetailFilter}
              handleChange={(e) =>
                onChange({
                  ...selectedFilters,
                  selectedDetailFilter: e.target.value,
                })
              }
            />
          </div>
          <div className="bottomButtons">
            <div className="clearAllContainer">
              <p className="clearAll" onClick={clearAllFilters}>
                Clear all filters
              </p>
            </div>
            <div className="applyButtonContainer">
              <Button
                buttonText="Apply"
                size="200px"
                fontSize="20px"
                handleOnClick={onApply}
              />
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default FullRideFilter;

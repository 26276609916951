import styled from "styled-components";
import InputRow from "../components/InputRow";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state";
import Button from "../components/Button";
import theme from "../theme";
import {
  setMedianAmounts,
  setMultipleSimulationResults,
} from "./multiple-simulation-slice";

import { Job } from "../types";
import {
  fetchSingleSimulationResults,
  updateAnnualLoan,
} from "./single-simulation-slice";
import { moneyFormat } from "./utils";
import {
  setSelectedJob,
  setSelectedSchool,
  setShowSimulationResults,
} from "../student-loans/financing-options-slice";

interface SimulationInputsProps {
  showOverlay: boolean;
  setShowOverlay?: (showOverlay: boolean) => void;
}

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    margin-bottom: 30px;
  }

  .inputs {
    width: 500px;
  }

  .stats-container {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 44px;
    margin-top: 24px;
    max-width: 100%;
    justify-content: flex-end;

    .stats {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      width: fit-content;

      .value {
        font-size: 24px;
        font-weight: 700;
        line-height: 110%;
      }

      .label {
        color: ${theme.colors.neutrals.darkGray};
        font-size: 16px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    .stats-container {
      gap: 0;
    }

    .inputs {
      width: 300px;
    }

    .stats {
      flex: 1;
      text-align: center;
    }
    .stats:nth-child(2) .value {
      margin: auto;
    }
    .stats-container > .stats > .label {
      font-size: 14px;
    }
    .value {
      font-size: 10px;
    }
    .row .row-label {
      font-size: 16px;
      text-align: center;
    }
    .definition {
      font-size: 17px;
    }
  }
`;

const selectCollegeCostsState = (state: RootState) => state.collegeCosts;
const selectFinancingOptionsState = (state: RootState) =>
  state.financingOptions;

const SimulationInputs: FC<SimulationInputsProps> = ({
  showOverlay,
  setShowOverlay,
}) => {
  const dispatch = useDispatch();
  const {
    dropdownOptions,
    selectedOptions,
    results: collegeCostResults,
  } = useSelector(selectCollegeCostsState);
  const {
    financingOptions,
    jobDropdownOptions,
    selectedJob,
    selectedSchool,
    collegeFinancialSources,
    results: collegeFinancialResults,
  } = useSelector(selectFinancingOptionsState);

  const [annualLoanAmount, setAnnualLoanAmount] = useState<number>();
  // eslint-disable-next-line
  const [totalCollegeCost, setTotalCollegeCost] = useState<number>();
  // eslint-disable-next-line
  const [totalLoanAmount, setTotalLoanAmount] = useState<number>();

  const runSimulation = () => {
    if (setShowOverlay) {
      setShowOverlay(false);
    }

    if (annualLoanAmount != null && selectedJob != null) {
      dispatch(updateAnnualLoan(annualLoanAmount));

      const selectedSchoolFinancing = financingOptions.find(
        (school) => school.collegeId === selectedSchool.collegeId,
      );
      let singleSimParams = new URLSearchParams([]);
      singleSimParams.append("collegeID", selectedSchool.collegeId.toString());
      singleSimParams.append(
        "remainingAnnualLoanAmount",
        selectedSchoolFinancing
          ? selectedSchoolFinancing.studentLoanAmount.toString()
          : "0",
      );
      singleSimParams.append("socCode", selectedJob.socCode.toString());

      fetch(
        `${process.env.REACT_APP_API_URL}/calculate-single-simulation?` +
          singleSimParams,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
        .then((response) => response.json())
        .then((responseJSON) => {
          dispatch(fetchSingleSimulationResults(responseJSON));
          dispatch(setShowSimulationResults(true));
        });

      const multiSimParams = new URLSearchParams([]);
      multiSimParams.append("collegeId", selectedSchool.collegeId);
      multiSimParams.append(
        "stateOfResidence",
        selectedOptions.stateOfResidence,
      );
      multiSimParams.append(
        "studentAidIndex",
        selectedSchoolFinancing
          ? selectedSchoolFinancing.studentAidIndexAmount
          : "0",
      );
      multiSimParams.append(
        "pellGrantAmount",
        selectedSchoolFinancing ? selectedSchoolFinancing.pellGrantAmount : "0",
      );
      multiSimParams.append(
        "familyContribution",
        selectedSchoolFinancing
          ? selectedSchoolFinancing.familyContributionAmount
          : "0",
      );
      multiSimParams.append(
        "parentPlusLoanAmount",
        selectedSchoolFinancing
          ? selectedSchoolFinancing.parentPlusLoanAmount
          : "0",
      );
      multiSimParams.append(
        "otherAmount",
        selectedSchoolFinancing ? selectedSchoolFinancing.otherAmount : "0",
      );
      multiSimParams.append("schoolYears", "4");
      multiSimParams.append("socCode", selectedJob.socCode.toString());

      fetch(
        `${process.env.REACT_APP_API_URL}/calculate-multiple-simulations?` +
          multiSimParams,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
        .then((response) => response.json())
        .then((responseJSON) => {
          const { results, median } = responseJSON;
          dispatch(setMultipleSimulationResults(results));
          dispatch(setMedianAmounts(median));
          dispatch(setShowSimulationResults(true));
        });
    }
  };

  const handleSchoolChange = (event: any, value: any) => {
    let selectedCollege = null;
    if (value) {
      const selectedSchoolName = value.label;
      selectedCollege = dropdownOptions.colleges.find(
        (college: { name: string }) => college.name === selectedSchoolName,
      );
    }

    if (selectedCollege) {
      dispatch(setSelectedSchool(selectedCollege));
      const selectedCollegeId = selectedCollege.collegeId;
      const selectedCollegeFinancialSources = collegeFinancialSources.find(
        (school) => school.collegeId === selectedCollegeId,
      );
      const selectedCollegeFinancingResults = collegeFinancialResults.find(
        (school) => school.collegeId === selectedCollegeId,
      );

      if (selectedCollegeFinancialSources) {
        setAnnualLoanAmount(selectedCollegeFinancialSources.loanAmount);
        setTotalCollegeCost(selectedCollegeFinancialSources.totalCollegeCost);
      }

      if (selectedCollegeFinancingResults) {
        setTotalLoanAmount(selectedCollegeFinancingResults.totalLoanAmount);
      } else {
        console.warn("Total loan amount not found.");
      }
    }
  };

  const handleJobChange = (event: any, value: any) => {
    let selectedJob = null;
    if (value) {
      const selectedJobTitle = value.label;
      selectedJob = jobDropdownOptions.jobs.find(
        (job: Job) => job.jobTitle === selectedJobTitle,
      );
    }

    if (selectedJob) {
      dispatch(setSelectedJob(selectedJob));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // show already selected school if it exists
    handleSchoolChange(null, {
      label: selectedSchool ? selectedSchool.name : "",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Style>
      {showOverlay && <h2>Edit Simulation</h2>}
      <div className="inputs">
        <InputRow
          rowLabel="School name"
          fieldTitle="Select school"
          selectedValue={selectedSchool ? selectedSchool.name : ""}
          dropdownOptions={
            // TODO: add preselected schools from College Costs page instead
            //dropdownOptions.colleges.map((college) => college.name)
            collegeCostResults.map(
              (collegeCostResult) => collegeCostResult.schoolName,
            )
          }
          handleChange={handleSchoolChange}
        />
        {selectedSchool && (
          <div className="stats-container">
            <div className="stats">
              <div className="value">{`$${moneyFormat(
                totalLoanAmount ? totalLoanAmount : 0,
              )}`}</div>
              <div className="label">Loan amount</div>
            </div>
            <div className="stats">
              <div className="value">of</div>
            </div>
            <div className="stats">
              <div className="value">{`$${moneyFormat(
                totalCollegeCost ? totalCollegeCost : 0,
              )}`}</div>
              <div className="label">Total cost</div>
            </div>
          </div>
        )}
        <InputRow
          rowLabel="Job title"
          fieldTitle="Select job title"
          selectedValue={selectedJob ? selectedJob.jobTitle : ""}
          dropdownOptions={jobDropdownOptions.jobs.map((job) => job.jobTitle)}
          handleChange={handleJobChange}
        />
        <p className="definition" style={{ textAlign: "right" }}>
          {selectedJob && (
            <div className="stats-container container-end">
              <div className="stats">
                <div className="value">{`$${moneyFormat(
                  selectedJob?.avgStartingSalary || 0,
                )}`}</div>
                <div className="label">Starting salary</div>
              </div>
            </div>
          )}
        </p>
      </div>
      <div className="button-container">
        <Button
          buttonText={"Run simulation"}
          handleOnClick={() => runSimulation()}
        />
      </div>
    </Style>
  );
};

export default SimulationInputs;

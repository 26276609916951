import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  results: [],
  median: {
    avgAnnualTakeHomePay: 0,
    avgAnnualIncome: 0,
  },
};

const slice = createSlice({
  name: "multipleSimulation",
  initialState,
  reducers: {
    setMultipleSimulationResults: (state, action) => {
      state.results = action.payload;
    },
    setMedianAmounts: (state, action) => {
      state.median = action.payload;
    },
  },
});

export const { setMultipleSimulationResults, setMedianAmounts } = slice.actions;

export default slice.reducer;

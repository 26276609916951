import { FC, ReactNode } from "react";
import styled from "styled-components";
import theme from "../theme";
import CloseButton from "../assets/icons/x-black.svg";

interface PopupProps {
  children: ReactNode;
  hideOverlay: () => void;
}

const Style = styled.div`
  background: ${theme.colors.neutrals.white};
  border-radius: 10px;
  padding: 48px;
  overflow-y: auto;
  height: fit-content;
  width: 50%;

  .content {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .close-section {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;

    .close-button {
      cursor: pointer;
    }
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    border-radius: 0px;
    padding: 24px;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    box-sizing: border-box;

    .close-section {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
`;

const Popup: FC<PopupProps> = ({ children, hideOverlay }) => {
  return (
    <Style>
      <div className="close-section">
        <img
          className="close-button"
          src={CloseButton}
          alt="close"
          onClick={hideOverlay}
        />
      </div>
      <div className="content">{children}</div>
    </Style>
  );
};

export default Popup;

import SummaryHeader from "./SummaryHeader";
import SummaryContent from "./SummaryContent";
import styled from "styled-components";
import theme from "../theme";

const Style = styled.div`
  .summary {
    text-align: center;
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    .summary {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`;
function DownloadSummary() {
  return (
    <Style>
      <h1 className="summary">Finiverse Summary</h1>
      <SummaryHeader />
      <SummaryContent />
    </Style>
  );
}

export default DownloadSummary;

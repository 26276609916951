import styled from "styled-components";
import theme from "../theme";
import searchIcon from "../assets/icons/search-icon.svg";
import filterIcon from "../assets/icons/filter-icon.svg";
import CloseButton from "../assets/icons/white-close-button.svg";

const Style = styled.div`
  .searchBarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .search-bar {
    height: 50px;
    border-width: 2px;
    border-style: solid;
    width: 90%;
    outline: none;
    border-color: ${theme.colors.primary};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 55px;
    font-family: ${theme.fonts.body};
    font-size: 20px;
  }

  .search-icon {
    height: 30px;
    width: 30px;
    position: absolute;
    left: 15px;
  }

  .filter-icon {
    height: 30px;
    width: 30px;
  }

  .filter-button {
    background-color: ${theme.colors.primary};
    height: 56px;
    border-width: 2px;
    border-style: solid;
    outline: none;
    border-color: ${theme.colors.primary};
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter-button:hover {
    cursor: pointer;
    opacity: 1;
  }

  .filter-button:active {
    opacity: 0.5;
  }

  .filter-popup {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  .filter-popup.show {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
  }

  @media (max-width: 768px) {
    .filter-icon {
      width: 22px;
      height: 22px;
    }

    .search-icon {
      margin-top: 5px;
    }

    .search-bar {
      height: 40px;
      border-width: 1.5px;
      margin-top: 5px;
    }

    .filter-button {
      height: 44.8px;
      margin-top: 5px;
    }
  }
  @media (max-width: 480px) {
    .filter-icon {
      width: 22px;
      height: 22px;
    }

    .search-icon {
      margin-top: 5px;
      height: 22px;
    }

    .search-bar {
      height: 40px;
      border-width: 1.5px;
      margin-top: 5px;
    }

    .filter-button {
      margin-top: 5px;
      height: 44.8px;
    }
  }
`;

interface SearchBarProps {
  searchString: string;
  popupVisible: boolean;
  setPopupVisible: (visible: boolean) => void;
  onChange: (search: string) => void;
  onApply?: () => void;
}

function SearchBar({
  searchString,
  popupVisible,
  setPopupVisible,
  onChange,
  onApply,
}: SearchBarProps) {
  const openPopup = () => {
    setPopupVisible(!popupVisible);
    if (popupVisible && onApply) {
      onApply();
    }
  };

  return (
    <Style>
      <div className="searchBarContainer">
        <img
          className="search-icon"
          src={searchIcon}
          alt="Search schools"
        ></img>
        <input
          className="search-bar"
          type="text"
          value={searchString}
          onChange={(e) => onChange(e.target.value)}
        ></input>

        <button
          className="filter-button"
          onClick={() => {
            openPopup();
          }}
        >
          <img
            className="filter-icon"
            alt={popupVisible ? "Close filter options" : "Filter schools"}
            src={popupVisible ? CloseButton : filterIcon}
          ></img>
        </button>
      </div>
    </Style>
  );
}

export default SearchBar;

import styled from "styled-components";
import theme from "../theme";

const Style = styled.div`
  padding: 50px;
  border: 2px ${theme.colors.secondary} solid;
  border-radius: 0px 0px 10px 10px;

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    font-size: 18px;
    padding: 25px;
    border: none;
  }
`;

const PrivateLoanSummary = () => {
  return (
    <Style>
      <div>
        <p>
          Private student loans are <strong>non-federal loans</strong> from
          banks or lenders. They often have{" "}
          <strong>higher interest rates</strong> and{" "}
          <strong>less flexible repayment options</strong> compared to federal
          loans.
        </p>
      </div>
    </Style>
  );
};

export default PrivateLoanSummary;

import { FC } from "react";
import styled from "styled-components";
import theme from "../theme";
import { moneyFormat } from "../simulations/utils";
import { useSelector } from "react-redux";
import { RootState } from "../state";
import GroupedBarGraph from "./GroupedBarGraph";

const Style = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-top: 10px;

  .box {
    border: 2px ${theme.colors.secondary} solid;
    max-width: 100%;
    border-radius: 10px;
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
  }

  .plans-container {
    max-width: 100%;
    display: flex;
    margin: 0px;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }

  .plans {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0px;
  }

  .title {
    font-weight: bold;
    font-size: 40px;
    font-family: ${theme.fonts.body};
  }

  .graph-container {
    max-width: 100%;
    bottom: 0;
    border: 2px ${theme.colors.secondary} solid;
    border-radius: 10px;
  }

  .graph-title {
    padding-left: 20px;
    padding-top: 12px;
    font-weight: bold;
    font-size: 40px;
    font-family: ${theme.fonts.body};
  }

  .graph {
    width: 100%;
    height: 70px;
  }

  .graph-labels {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .graph-label {
      display: flex;
      gap: 10px;
    }

    .netPrice {
      font-weight: bold;
    }
  }

  .square {
    height: 12px;
    width: 12px;
  }
  .graph-Box {
    height: 300px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .tooltip-container {
    background: ${theme.colors.neutrals.white};
    border-radius: 20px;
    border: 2px solid ${theme.colors.neutrals.darkGray};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 400px;

    .tooltip-expenses {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding: 10px 15px;

      p {
        margin: 0;
      }
    }

    .idr-color {
      color: ${theme.colors.graphColors.mediumGreen};
      font-weight: bold;
    }

    .standard-color {
      color: ${theme.colors.secondary};
      font-weight: bold;
    }
  }

  .mobile-tooltip {
    width: 200px !important;
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    font-size: 12px;
    padding: 10px;

    .plans {
      flex-direction: column;
    }

    .title {
      font-size: 20px;
    }
    .box {
      padding: 25px;
    }
  }
`;
interface FederalLoanRepaymentSummaryProps {
  selectedCollegeId: string;
}

const FederalLoanRepaymentSummary: FC<
  FederalLoanRepaymentSummaryProps
> = () => {
  const singleSimulationResults = useSelector(
    (state: RootState) => state.singleSimulation.results,
  );

  const standardMonthlyPayment =
    singleSimulationResults.summaryStats.averageMonthlyStand;

  const standardTotalPayment =
    singleSimulationResults.summaryStats.totalStandPayment;

  const idrMonthlyPayment =
    singleSimulationResults.summaryStats.averageMonthlyIDR;

  const idrPaymentPeriod =
    singleSimulationResults.summaryStats.idrpaymentPeriod;

  const idrTotalPayment = singleSimulationResults.summaryStats.totalIdrPayment;

  const standardVsIDRData = [
    {
      income: 0,
      standard: standardMonthlyPayment,
      idr: 0,
    },
    {
      income: 10000,
      standard: standardMonthlyPayment,
      idr: 0,
    },
    {
      income: 20000,
      standard: standardMonthlyPayment,
      idr: 0,
    },
    {
      income: 30000,
      standard: standardMonthlyPayment,
      idr: 0,
    },
    {
      income: 40000,
      standard: standardMonthlyPayment,
      idr: standardMonthlyPayment <= 60 ? standardMonthlyPayment : 60,
    },
    {
      income: 50000,
      standard: standardMonthlyPayment,
      idr: standardMonthlyPayment <= 153 ? standardMonthlyPayment : 153,
    },
    {
      income: 60000,
      standard: standardMonthlyPayment,
      idr: standardMonthlyPayment <= 227 ? standardMonthlyPayment : 227,
    },
  ];

  return (
    <Style>
      <div className="plans-container">
        <div className="plans">
          <div className="box standard-plan">
            <h2 className="summary-card-title">Standard Repayment Plan</h2>
            <p>
              In a standard repayment plan, you pay a{" "}
              <strong>fixed amount</strong> every month for a set period of time{" "}
              <strong>(usually 10 years)</strong>.
            </p>
            <p>
              <strong>Average monthly payment</strong>: $
              {moneyFormat(standardMonthlyPayment)}
              <br />
              <strong>Payment period</strong>: 10 years
              <br />
              <strong>Payment total</strong>: $
              {moneyFormat(standardTotalPayment)}
            </p>
          </div>
          <div className="box idr-plan">
            <h2 className="summary-card-title">Income-Driven Repayment Plan</h2>
            <p>
              Income-Driven Repayment (IDR) plans adjust federal student loan
              payments based on income and family size,{" "}
              <strong>
                capping monthly payments at a percentage of discretionary income
              </strong>
              {", "}with possible loan forgiveness after 20-25 years.
            </p>
            <p>
              <strong>Average monthly payment</strong>: $
              {moneyFormat(idrMonthlyPayment)} <br />
              <strong>Payment period</strong>: {idrPaymentPeriod} years <br />
              <strong>Payment total</strong>: ${moneyFormat(idrTotalPayment)}
            </p>
          </div>
        </div>
        <div className="graph-container box">
          <h2 className="summary-card-title">
            Standard Repayment Plan vs. IDR Plan
          </h2>
          <p>
            If your income is low or variable,{" "}
            <b>
              Income-Driven Repayment (IDR) can be a better option because your
              monthly payment amount will be based on your salary.
            </b>{" "}
            <br /> <br />
            For example, if you are a household of one person and{" "}
            <b>
              if your income is below $32,800, your monthly payment will be $0
              under the IDR plan.
            </b>
            <br /> <br />
            Your monthly payment under the IDR plan will also never exceed your
            monthly payment under the Standard Repayment Plan.{" "}
            <a href="https://studentaid.gov/announcements-events/save-plan">
              Learn more about the SAVE plan.
            </a>
          </p>
          <div className="graph-Box">
            <GroupedBarGraph data={standardVsIDRData} />
          </div>
        </div>
      </div>
    </Style>
  );
};

export default FederalLoanRepaymentSummary;

import { useEffect, useState } from "react";
import styled from "styled-components";
import NavButtonFooter from "../components/NavButtons";
import Button from "../components/Button";
// import IconTextButton from "../components/Button";
import theme from "../theme";
import ShootingStarWhiteIcon from "../assets/icons/shooting-star-white.svg";
import SingleSimulationView from "./SingleSimulationView";
import MultipleSimulationView from "./MultipleSimulationView";
import Overlay from "../components/Overlay";
import Popup from "../components/Popup";
import SimulationInputs from "./SimulationInputs";
// import RestartButton from "../assets/icons/restart-white.svg";
import EditIcon from "../assets/icons/edit.svg";
import EstimateNote from "../components/EstimateNote";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state";
import { addActivePage } from "../home/navigation-slice";
import SimulationIntro from "./SimulationIntro";
import { useNavigate } from "react-router-dom";
import {
  setSelectedJob,
  setSelectedSchool,
  setShowSimulationResults,
} from "../student-loans/financing-options-slice";

const Style = styled.div`
  .written-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 30px;
    line-height: 140%;
    text-align: left;
    width: 100%;

    .actions-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .button-container {
        margin-top: 36px;
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;
        flex: 1;
      }

      .button-container-two {
        margin-top: 36px;
        margin-bottom: 24px;
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 14px;
        cursor: pointer;
      }

      .new-try-text {
        font-family: ${theme.fonts.body};
        color: ${theme.colors.secondary};
        font-weight: 700;
      }

      .rerun-button {
        cursor: pointer;
        color: ${theme.colors.secondary};
        font-weight: 700;
        width: 35%;
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 8px;

        .rerun-button-icon {
          height: 30px;
        }
      }
    }

    .simulation-container {
      border: 2px solid ${theme.colors.secondary};
      border-radius: 6px;
      padding: 30px;
      width: 100%;
      @media (max-width: ${theme.breakpoints.medium}px) {
        border: 0;
        border-top: 0px solid ${theme.colors.secondary};
        border-bottom: 0px solid ${theme.colors.secondary};
        padding: 0px;
        width: auto;
      }
    }

    .timeline-summary-section {
      padding-bottom: 24px;
    }

    .timeline-summary-heading {
      color: #031d44;
      font-size: 18px;
      font-weight: 600;
    }

    .timeline-events {
      margin-top: 24px;
      display: flex;
      gap: 24px;
      justify-content: stretch;
      margin-bottom: 24px;
    }

    .financial-summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 24px;
      padding-right: 24px;

      .value-container {
        background: rgba(175, 210, 233, 0.5);
        border-radius: 10px;
        padding: 20px;
        font-size: 22px;
      }

      .turquoise {
        color: ${theme.colors.graphColors.turquoise};
      }

      .red {
        color: ${theme.colors.graphColors.redOrange};
      }

      .purple {
        color: ${theme.colors.primary};
      }

      .equation-value {
        font-size: 22px;
      }

      .stats {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        width: fit-content;

        .value {
          font-size: 24px;
          font-weight: 700;
          line-height: 110%;
        }

        .label {
          color: ${theme.colors.neutrals.darkGray};
          font-size: 16px;
        }
      }
    }

    .chart-summary {
      .graph {
        width: 700px;
        height: 600px;
      }
    }

    .simulation-description {
      padding-top: 1.3em;
      padding-bottom: 1.3em;
      width: 700px;
    }

    .clickable {
      cursor: pointer;
    }
  }
`;

const SimulationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const selectedInputs = useSelector(selectedState);
  // const { selectedJob } = useSelector(selectFinancingOptionsState);
  const [showSingleSimulation, setShowSingleSimulation] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const { showSimulationResults, selectedJob, selectedSchool } = useSelector(
    (state: RootState) => state.financingOptions,
  );
  const { activePages } = useSelector((state: RootState) => state.navigation);
  const { showNetPriceResults, results } = useSelector(
    (state: RootState) => state.collegeCosts,
  );

  const hideOverlay = () => {
    setShowOverlay(false);
    document.body.classList.remove("noscroll");
  };

  useEffect(() => {
    if (results.length > 0) {
      if (!activePages.includes("RISK_SIMULATION")) {
        dispatch(addActivePage("RISK_SIMULATION"));
      }
    } else {
      navigate("/college-costs");
    }
  }, [activePages, showNetPriceResults, results.length, navigate, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {}, [showSimulationResults]);

  // API to rerun simulations 1 time
  // const rerunSingleSimulation = () => {
  //   if (selectedInputs.annualLoanAmount != null && selectedJob != null) {
  //     fetch(
  //       `${process.env.REACT_APP_API_URL}/calculate-single-simulation?` +
  //         new URLSearchParams([
  //           [
  //             "remainingAnnualLoanAmount",
  //             selectedInputs.annualLoanAmount.toString(),
  //           ],
  //           ["socCode", selectedJob.socCode.toString()],
  //         ]),
  //       {
  //         method: "GET", // *GET, POST, PUT, DELETE, etc.
  //         mode: "cors", // no-cors, *cors, same-origin
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((responseJSON) => {
  //         dispatch(fetchSingleSimulationResults(responseJSON));
  //         navigate("/risk-simulation");
  //       });
  //   }
  // };

  //When screen resizes, reload relative component
  const handleResize = () => setWindowWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, [windowWidth]);

  // TODO: add API to rerun simulations 1000 times
  const mobileWidth = theme.breakpoints.xsmall;
  return (
    <Style>
      {showOverlay && (
        <Overlay>
          <Popup
            hideOverlay={hideOverlay}
            children={
              <SimulationInputs
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
              />
            }
          />
        </Overlay>
      )}
      {showSimulationResults && selectedJob && selectedSchool ? (
        <div className="written-content">
          {showSingleSimulation ? (
            <>
              <div className="instruction-text special-text">
                To see how financial risks could affect you later in life, let’s
                randomly simulate one potential version of your life path for
                the <b>first 20 years of your life after college.</b>
              </div>
              <br />
              <div className="instruction-text">
                In any given 3-month period, you can be either 1) employed in
                the degree-related job you selected (highest-paying option), 2)
                employed in an unrelated-degree job (such as a waitress or
                barista) or 3) unemployed and receiving unemployment benefits.
              </div>
            </>
          ) : (
            <>
              <div className="instruction-text special-text">
                A single simulation probably doesn’t represent your actual
                future life path because there are countless versions of your
                life that could happen based on what decisions you make.
              </div>
              <br />
              <div className="instruction-text">
                Let’s take a look at a larger sample of potential life paths by{" "}
                <b>running this simulation 1,000 times.</b>
              </div>
            </>
          )}
          <EstimateNote />
          <div className="actions-container">
            <div className="button-container">
              <Button
                buttonText={window.innerWidth < mobileWidth ? "" : "Edit"}
                buttonLeftIcon={window.innerWidth < mobileWidth ? EditIcon : ""}
                handleOnClick={() => {
                  // TODO: show Edit Inputs popup instead
                  // setShowOverlay(true);
                  dispatch(setShowSimulationResults(false));
                  dispatch(
                    setSelectedSchool({
                      collegeId: "",
                      name: "",
                    }),
                  );
                  dispatch(setSelectedJob(undefined));
                }}
              />
            </div>
            {/* TODO: debug this and add it back */}
            {/* <div className="button-container-two" onClick={rerunSingleSimulation}>
            {window.innerWidth < mobileWidth ? (
              <IconTextButton
                buttonText=""
                buttonLeftIcon={RestartButton}
                bgColor={theme.colors.secondary}
                borderColor={theme.colors.secondary}
                handleOnClick={() => setShowSingleSimulation(true)}
              ></IconTextButton>
            ) : (
              <>
                
                <img src={RestartButton} alt="restart button" />
                <p className="new-try-text">
                  {showSingleSimulation
                    ? "Try another simulation"
                    : "Rerun 1,000 times"}
                </p>
              </>
            )}
          </div> */}
          </div>
          <div className="simulation-container">
            {showSingleSimulation ? (
              <SingleSimulationView />
            ) : (
              <MultipleSimulationView />
            )}
          </div>
          <NavButtonFooter
            prevPageButtonText="Financing"
            prevPageLink="/financing-options"
            orangeNextButton={true}
            nextPageButtonText={
              showSingleSimulation
                ? "Simulate 1,000 times"
                : window.innerWidth < theme.breakpoints.medium
                ? "Single simulation"
                : "Run single simulation again"
            }
            nextPageIcon={ShootingStarWhiteIcon}
            actionOnClick={() => setShowSingleSimulation(!showSingleSimulation)}
            nextPageLink="/risk-simulation"
          />
        </div>
      ) : (
        <SimulationIntro />
      )}
    </Style>
  );
};

export default SimulationPage;

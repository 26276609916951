import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../state";
import theme from "../theme";
import { FC } from "react";
import { moneyFormat } from "../simulations/utils";
import SimpleBarGraph from "../components/graphs/HorizontalSimpleBarGraph";
import { studentLoanTypeLegendMap } from "../constants";
import { FinancingOptionsResults } from "../types";

const Style = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 50px;
  box-sizing: border-box;
  border: 2px ${theme.colors.secondary} solid;
  border-radius: 0px 0px 10px 10px;

  .graph {
    width: 100%;
    height: 70px;
  }

  .graph-labels {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    margin-top: 36px;

    .graph-label {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .total-loans-container {
    margin-bottom: 16px;
  }

  .square {
    height: 12px;
    width: 12px;
  }

  .familyContribution {
    background: ${theme.colors.graphColors.turquoise};
  }

  .parentPlusLoanAmount {
    background: ${theme.colors.graphColors.lavender};
  }

  .loanAmount {
    background: ${theme.colors.graphColors.redOrange};
  }

  .totalSubsidized {
    background: ${theme.colors.graphColors.eggplantPurple};
  }

  .totalUnsubsidized,
  .other {
    background: ${theme.colors.graphColors.mediumGreen};
  }

  .totalPrivate {
    background: ${theme.colors.graphColors.indigo};
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    padding: 25px;
    font-size: 12px;
    border: none;
  }
`;

interface StudentLoanSummaryProps {
  selectedCollegeId: string;
}

const StudentLoanSummary: FC<StudentLoanSummaryProps> = ({
  selectedCollegeId,
}) => {
  const financingOptionResults = useSelector(
    (state: RootState) => state.financingOptions.results,
  );
  const selectedCollegeFinancingResult = financingOptionResults.find(
    (school) => school.collegeId === selectedCollegeId,
  );
  const financingOptionsCollegeFinances = useSelector(
    (state: RootState) => state.financingOptions.collegeFinancialSources,
  );
  const selectedCollegeFinancingSource = financingOptionsCollegeFinances.find(
    (school) => school.collegeId === selectedCollegeId,
  );

  return (
    <Style>
      {selectedCollegeFinancingSource && selectedCollegeFinancingResult && (
        <div className="loan-summary-container">
          <div className="annual-title-container total-loans-container">
            <div className="summary-card-title outlined-stat blue-border">
              Total Student Loan Balance: $
              {moneyFormat(selectedCollegeFinancingResult?.totalLoanAmount)}
            </div>
          </div>
          <p>
            This is an estimate of how much you could owe (including interest)
            by the end of college.
          </p>
          <p>
            Remember, interest will be added to your balance every year after
            graduation until you pay off all of your student loans.
          </p>
          {selectedCollegeFinancingSource.loanAmount > 0 && (
            <div className="summary-graph-container">
              <div className="graph-labels">
                {Object.entries(studentLoanTypeLegendMap).map(
                  ([key, value]) => (
                    <div className="graph-label">
                      <div className={`square ${key}`}></div>
                      {value}: $
                      {selectedCollegeFinancingResult
                        ? moneyFormat(
                            selectedCollegeFinancingResult[
                              key as keyof FinancingOptionsResults
                            ],
                          )
                        : 0}
                    </div>
                  ),
                )}
              </div>
              <div className="graph">
                <SimpleBarGraph
                  data={[selectedCollegeFinancingResult]}
                  layout="horizontal"
                  xAxisKey="schoolName"
                  yAxisKeys={[
                    "totalSubsidized",
                    "totalUnsubsidized",
                    "totalPrivate",
                  ]}
                  showYAxis={true}
                  xAxisLabel=""
                  yAxisLabel=""
                  legendWidth={180}
                  legendLabelMap={studentLoanTypeLegendMap}
                  colors={[
                    theme.colors.graphColors.eggplantPurple,
                    theme.colors.graphColors.mediumGreen,
                    theme.colors.graphColors.indigo,
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </Style>
  );
};

export default StudentLoanSummary;

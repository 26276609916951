import { FC } from "react";
import styled from "styled-components";
import theme from "../theme";
import { CollegeFinancialSources } from "../types";
import SimpleBarGraph from "../components/graphs/HorizontalSimpleBarGraph";
import {
  averageAidValues,
  calculatedAidValues,
  financialSourceLegendMap,
} from "../constants";
import { moneyFormat } from "../simulations/utils";

const Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 50px;
  box-sizing: border-box;
  border: 2px ${theme.colors.secondary} solid;
  border-radius: 0px 0px 10px 10px;

  .sticker-price {
    color: gray;
    text-decoration-line: line-through;
  }

  .graph-title {
    margin-bottom: 24px;
  }

  .graph-labels {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;

    .graph-label {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .netPrice {
      font-weight: bold;
    }
  }

  .square {
    height: 12px;
    width: 12px;
  }

  .federalAid,
  .averageFederalAid {
    background: ${theme.colors.lightOrange};
  }

  .stateAndLocalAid,
  .averageStateAndLocalAid {
    background: ${theme.colors.lightGreen};
  }

  .annualNetPrice,
  .averageAnnualNetPrice {
    background: ${theme.colors.lightPrimary};
  }

  .institutionalAid,
  .averageInstitutionalAid {
    background: ${theme.colors.graphColors.lightLavender};
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    font-size: 12px;
    padding: 25px;
    border: none;

    .summary-card-title {
      font-size: 18px;
    }
  }
`;
interface CollegeCostsSummaryProps {
  stickerPrice: number;
  netPrice: number;
  financialSources: CollegeFinancialSources;
  hasCalculatedNetPrice: boolean;
}
const CollegeCostSummary: FC<CollegeCostsSummaryProps> = ({
  stickerPrice,
  netPrice,
  financialSources,
  hasCalculatedNetPrice,
}) => {
  // Use average aid values if user chose to proceed without calculating SAI
  const selectedAidValues = hasCalculatedNetPrice
    ? calculatedAidValues
    : averageAidValues;
  const collegeCostGraphLabels = Object.entries(
    financialSourceLegendMap,
  ).filter(([key, value]) => selectedAidValues.includes(key));
  return (
    <Style>
      <div className="summary-text-container">
        <div className="annual-title-container">
          <div className="summary-card-title sticker-price">
            Sticker Price: ${moneyFormat(stickerPrice)}
          </div>
          <div className="summary-card-title outlined-stat blue-border">
            Net Price: ${moneyFormat(netPrice)}
          </div>
        </div>

        <p>
          The <strong>sticker price</strong> is the total annual cost of
          attendance displayed on a college's website.
        </p>
        <p>
          The <strong>net price</strong> is the amount you and your family
          actually need to pay for college each year after receiving government
          and institutional grants. Think of it like a discount (based on your
          income).
        </p>
      </div>

      {netPrice !== 0 && (
        <div className="summary-graph-container">
          <div className="graph-labels">
            {collegeCostGraphLabels.map(([key, value]) => {
              return (
                <div
                  className={`graph-label ${
                    key === "annualNetPrice" || key === "averageAnnualNetPrice"
                      ? "netPrice"
                      : ""
                  }`}
                >
                  <div className={`square ${key}`}></div>
                  {value}: $
                  {moneyFormat(
                    Number(
                      financialSources[key as keyof CollegeFinancialSources],
                    ),
                  )}
                </div>
              );
            })}
          </div>
          <div className="graph">
            <SimpleBarGraph
              data={[financialSources]}
              layout="horizontal"
              xAxisKey="schoolName"
              yAxisKeys={calculatedAidValues.sort()}
              showYAxis={true}
              xAxisLabel=""
              yAxisLabel=""
              legendWidth={180}
              legendLabelMap={financialSourceLegendMap}
              colors={[
                theme.colors.lightPrimary,
                theme.colors.lightOrange,
                theme.colors.graphColors.lightLavender,
                theme.colors.lightGreen,
              ]}
            />
          </div>
        </div>
      )}
    </Style>
  );
};

export default CollegeCostSummary;

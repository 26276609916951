import Nav from "../components/Nav";
import DownloadSummary from "./DownloadSummary";
import Button from "../components/Button";
import DownloadIcon from "../assets/download-icon.svg";
import styled from "styled-components";
import theme from "../theme";

const Style = styled.div`
  // use to print exact colors
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  overflow-x: hidden;

  .download-button {
    position: fixed;
    top: 90vh;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: ${theme.breakpoints.medium}px) {
    .download-button {
      width: 50%;
    }
  }

  @media print {
    .download-button {
      display: none;
    }
  }
`;

function DownloadPage() {
  const handlePrint = () => {
    window.print();
  };
  return (
    <Style>
      <Nav />
      <DownloadSummary />
      <Button
        className="download-button"
        buttonLeftIcon={DownloadIcon}
        buttonText={"Download Summary"}
        handleOnClick={handlePrint}
      />
    </Style>
  );
}

export default DownloadPage;

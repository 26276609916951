import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../components/Button";
import { RootState } from "../state";
import {
  setCurrentCollegeFinancialSources,
  setCurrentFinancingOptionsResult,
  setResults,
  setSelectedFinancingSourcesResult,
  setShowResults,
  updateFinancingOptionsBySchoolId,
  updateLoanAccumulationStepIndex,
} from "./financing-options-slice";
import { StudentLoansResults } from "./StudentLoansResults";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import Overlay from "../components/Overlay";
import NavButtonFooter from "../components/NavButtons";
import CollegeCostsSAI from "../college-costs/SAIPopup";
import theme from "../theme";
import ThemedTabs, { StyledTab } from "../components/ThemedTabs";
import { addActivePage } from "../home/navigation-slice";
import { roundToNearest } from "../utils";

const Style = styled.div`
  .form-input-background {
    background: #e3e2ea;
    padding: 30px;
    border-radius: 10px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .input-row {
    margin-top: 24px;
    margin-bottom: 44px;
  }

  .empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .empty-state-text {
      font-style: italic;
      text-align: center;
    }
  }
`;

const selectFinancingOptionsState = (state: RootState) =>
  state.financingOptions;
const selectCollegeCostsState = (state: RootState) => state.collegeCosts;

const mobileWidth = theme.breakpoints.xlarge;

export const StudentLoansPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activePages } = useSelector((state: RootState) => state.navigation);
  const {
    collegeFinancialSources,
    results: financingOptionsResults,
    currentCollegeFinancialSources,
    financingOptions,
  } = useSelector(selectFinancingOptionsState);
  const { results: collegeCostResults, showNetPriceResults } = useSelector(
    selectCollegeCostsState,
  );
  const [showOverlay, setShowOverlay] = useState(false);

  const hideOverlay = () => {
    setShowOverlay(false);
    document.body.classList.remove("noscroll");
  };

  const [selectedCollegeId, setSelectedCollegeId] = useState(
    collegeFinancialSources[0]?.collegeId || undefined,
  );

  useEffect(() => {
    if (collegeCostResults.length > 0) {
      if (!activePages.includes("FINANCING")) {
        dispatch(addActivePage("FINANCING"));
      }
    } else {
      navigate("/college-costs");
    }
  }, [
    activePages,
    showNetPriceResults,
    collegeCostResults.length,
    navigate,
    dispatch,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const collegeFinancialSourcesResult =
      collegeFinancialSources && collegeFinancialSources.length > 0
        ? collegeFinancialSources.find(
            (collegeFinancialSourcesResult) =>
              collegeFinancialSourcesResult.collegeId === selectedCollegeId,
          )
        : null;

    const financingOptionsResult =
      financingOptionsResults && financingOptionsResults.length > 0
        ? financingOptionsResults.find(
            (school) => school.collegeId === selectedCollegeId,
          )
        : null;

    const financingSourcesResult =
      financingOptions && financingOptions.length > 0
        ? financingOptions.find(
            (school) => school.collegeId === selectedCollegeId,
          )
        : null;

    if (collegeFinancialSourcesResult) {
      dispatch(
        setCurrentCollegeFinancialSources(collegeFinancialSourcesResult),
      );
    }
    if (financingOptionsResult) {
      dispatch(setCurrentFinancingOptionsResult(financingOptionsResult));
    }
    // this is confusing, we should refactor these names
    // this is the financingOptions for inputs
    if (financingSourcesResult) {
      dispatch(setSelectedFinancingSourcesResult(financingSourcesResult));
    }
  }, [
    selectedCollegeId,
    collegeFinancialSources,
    financingOptionsResults,
    financingOptions,
    dispatch,
  ]);

  useEffect(() => {
    const collegeIds = collegeFinancialSources.map(
      (college) => college.collegeId,
    );

    const loanAmounts = financingOptions.map(
      (college) => college.studentLoanAmount,
    );

    let params = new URLSearchParams([]);
    collegeIds.map((id) => params.append("collegeIds", id.toString()));
    loanAmounts.map((loanAmount) =>
      params.append("annualLoanAmounts", loanAmount.toString()),
    );

    fetch(`${process.env.REACT_APP_API_URL}/get-loan-options?` + params, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJSON) => {
        dispatch(setResults(responseJSON));
        dispatch(setShowResults(true));
      });
  }, [dispatch, collegeFinancialSources, financingOptions]);

  // Set the default financing options for each school (only once)
  useEffect(() => {
    financingOptions.forEach((financingOption) => {
      // If the user has updated this college's inputs, do not set them back to default values
      if (financingOption.userHasUpdatedThis) {
        return;
      }

      const collegeId = financingOption.collegeId;
      const collegeFinancialSource = collegeFinancialSources.find(
        (college) => college.collegeId === collegeId,
      );

      if (!collegeFinancialSource) {
        return;
      }

      const parentPlusLoan = roundToNearest(
        collegeFinancialSource.annualNetPrice * 0.08,
        500,
      );

      const familyContribution = roundToNearest(
        collegeFinancialSource.annualNetPrice * 0.4,
        500,
      );

      const other = roundToNearest(
        collegeFinancialSource.annualNetPrice * 0.4,
        500,
      );

      const studentLoan =
        collegeFinancialSource.annualNetPrice -
        parentPlusLoan -
        familyContribution -
        other;

      dispatch(
        updateFinancingOptionsBySchoolId({
          collegeId,
          dataToUpdate: {
            parentPlusLoanAmount: parentPlusLoan.toString(),
            familyContributionAmount: familyContribution.toString(),
            otherAmount: other.toString(),
            studentLoanAmount: studentLoan.toString(),
          },
        }),
      );
    });
    // eslint-disable-next-line
  }, [dispatch]); // don't add any more dependencies here or default values will be set every time the tab switches

  return (
    <Style>
      {showOverlay && (
        <Overlay>
          <Popup
            hideOverlay={hideOverlay}
            children={
              <CollegeCostsSAI
                showOverlay={showOverlay}
                hideOverlay={hideOverlay}
                // TODO: refactor code to add calculation function here!
                calculateCosts={() => {}}
              />
            }
          />
        </Overlay>
      )}

      {selectedCollegeId ? (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <ThemedTabs
              tabValue={selectedCollegeId}
              setSelectedTab={(
                event: React.SyntheticEvent,
                newValue: string,
              ) => {
                setSelectedCollegeId(newValue);
                dispatch(updateLoanAccumulationStepIndex(0));
              }}
              aria-label="Selected Schools"
            >
              {collegeCostResults.map((collegeCostResult) => (
                <StyledTab
                  label={collegeCostResult.schoolName}
                  value={collegeCostResult.collegeId}
                  id={collegeCostResult.schoolName}
                />
              ))}
            </ThemedTabs>
          </Box>
          {currentCollegeFinancialSources &&
            financingOptionsResults &&
            collegeFinancialSources && (
              <StudentLoansResults selectedCollegeId={selectedCollegeId} />
            )}
        </>
      ) : (
        <div className="empty-container">
          <p className="empty-state-text">
            No colleges have been selected yet.
          </p>
          <Button
            handleOnClick={() => navigate("/college-costs")}
            buttonText={"+ Add school"}
          />
        </div>
      )}
      <NavButtonFooter
        prevPageButtonText={
          window.innerWidth < mobileWidth ? "Back" : "College Costs"
        }
        prevPageLink="/college-costs"
        nextPageButtonText={
          window.innerWidth < mobileWidth ? "Next" : "Risk Simulation"
        }
        nextPageLink="/risk-simulation"
      />
    </Style>
  );
};

export default StudentLoansPage;

import { FC, useEffect } from "react";
import styled from "styled-components";
import InputOption from "../components/InputOption";
import { useForm } from "react-hook-form";
import Slider from "../components/Slider";
import { RootState } from "../state";
import { useDispatch, useSelector } from "react-redux";
import { updateFinancingOptionsBySchoolId } from "./financing-options-slice";
import { setCollegeFinancialSources } from "./financing-options-slice";
import theme from "../theme";
import { CollegeFinancialSources } from "../types";

interface FinancingOptionsInputsProps {
  selectedCollegeId: string;
}

const Style = styled.div`
  .financing-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 2em;

    .column {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }

  .heading {
    // do all heading tags need line-height increase? if so maybe add it globally?
    line-height: 1.5;
  }

  @media (max-width: ${theme.breakpoints.large}px) {
    .financing-options {
      flex-direction: column;
    }

    .input-option {
      padding: 0;

      .input-title {
        flex: 2;
      }

      .option {
        flex: 2;
      }
    }
  }
`;

interface IFormInputs {}

const FinancingOptionsInputs: FC<FinancingOptionsInputsProps> = ({
  selectedCollegeId,
}) => {
  const form = useForm<IFormInputs>();
  const dispatch = useDispatch();
  const {
    currentCollegeFinancialSources,
    selectedFinancialOptionsResult,
    financingOptions,
    customSAIValue,
  } = useSelector((state: RootState) => state.financingOptions);

  const showNetPriceResults = useSelector(
    (state: RootState) => state.collegeCosts.showNetPriceResults,
  );

  const onSliderChange = (newValues: { name: string; value: number }[]) => {
    dispatch(
      updateFinancingOptionsBySchoolId({
        collegeId: selectedFinancialOptionsResult.collegeId,
        dataToUpdate: {
          familyContributionAmount: newValues[0].value.toString(),
          parentPlusLoanAmount: newValues[1].value.toString(),
          otherAmount: newValues[2].value.toString(),
          studentLoanAmount: newValues[3].value.toString(),
        },
        userUpdatedThisValue: true,
      }),
    );
  };

  const onInputChange = (name: string, value: string) => {
    dispatch(
      updateFinancingOptionsBySchoolId({
        collegeId: selectedFinancialOptionsResult.collegeId,
        dataToUpdate: {
          [name]: value,
        },
        userUpdatedThisValue: true,
      }),
    );
  };

  const { selectedOptions, results } = useSelector(
    (state: RootState) => state.collegeCosts,
  );

  useEffect(() => {
    // recalculate total loan amount
    let collegeIds: string[] = results.map((college) => college.collegeId);
    let familyContributions: string[] = financingOptions.map(
      (financingOption) => financingOption.familyContributionAmount,
    );
    let parentPlusLoanAmounts: string[] = financingOptions.map(
      (financingOption) => financingOption.parentPlusLoanAmount,
    );
    let otherAmounts: string[] = financingOptions.map(
      (financingOption) => financingOption.otherAmount,
    );

    let params = new URLSearchParams([
      ["stateOfResidence", selectedOptions.stateOfResidence],
      [
        "studentAidIndex",
        (
          selectedFinancialOptionsResult.studentAidIndexAmount || "0"
        ).toString(),
      ],
      [
        "pellGrantAmount",
        (selectedFinancialOptionsResult.pellGrantAmount || "0").toString(),
      ],
    ]);

    if (!customSAIValue) {
      params.append("familyIncome", financingOptions[0].parentIncome);
    }

    collegeIds.map((id) => params.append("collegeIds", id.toString()));
    familyContributions.map((familyContribution) =>
      params.append("familyContributions", familyContribution.toString()),
    );
    parentPlusLoanAmounts.map((parentPlusLoanAmount) =>
      params.append("parentPlusLoanAmounts", parentPlusLoanAmount.toString()),
    );
    otherAmounts.map((otherAmount) =>
      params.append("otherAmounts", otherAmount.toString()),
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/calculate-college-financial-sources?` +
        params,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error fetching college financial sources");
        }
        return response.json();
      })
      .then((responseJSON: CollegeFinancialSources[]) => {
        if (!showNetPriceResults) {
          // Set the net price to the average net price if the user hasn't inputted their SAI
          responseJSON.forEach((college) => {
            college.annualNetPrice = college.averageAnnualNetPrice;
            college.federalAid = college.averageFederalAid;
            college.stateAndLocalAid = college.averageStateAndLocalAid;
            college.institutionalAid = college.averageInstitutionalAid;
          });
        }

        dispatch(setCollegeFinancialSources(responseJSON));
      })
      .catch((error) => {
        console.error("Error fetching college financial sources:", error);
      });
  }, [
    dispatch,
    results,
    financingOptions,
    selectedOptions.stateOfResidence,
    currentCollegeFinancialSources.annualNetPrice,
    selectedFinancialOptionsResult.studentAidIndexAmount,
    selectedFinancialOptionsResult.pellGrantAmount,
    selectedFinancialOptionsResult.familyContributionAmount,
    selectedFinancialOptionsResult.otherAmount,
    selectedFinancialOptionsResult.parentPlusLoanAmount,
    selectedFinancialOptionsResult.collegeId,
    showNetPriceResults,
    customSAIValue,
  ]);

  return (
    <Style>
      <h2 className="heading">How do I pay the net price each year?</h2>
      <p>
        There are different options to pay for college. If you or your family
        don’t have enough money to pay for college, there are outside grants,
        scholarships, and loans available.
      </p>
      <div className="financing-options">
        <div className="column">
          <InputOption
            label="Family contribution"
            form={form}
            currentCollegeData={selectedFinancialOptionsResult}
            tooltip={
              <>
                How much money your family can contribute to your college cost.
              </>
            }
            name="familyContributionAmount"
            saveInputValue={(newValue) =>
              onInputChange("familyContributionAmount", newValue)
            }
          />

          <InputOption
            label="Parent PLUS loans"
            form={form}
            currentCollegeData={selectedFinancialOptionsResult}
            tooltip={
              <>
                Parent PLUS loans are a type of federal loan that parents can
                get to help pay for their child's college.
              </>
            }
            name="parentPlusLoanAmount"
            saveInputValue={(newValue) => {
              onInputChange("parentPlusLoanAmount", newValue);
            }}
          />
        </div>

        <div className="column">
          <InputOption
            label="Other"
            form={form}
            currentCollegeData={selectedFinancialOptionsResult}
            tooltip={
              <>
                Any other financial sources such as outside scholarships,
                grants, work study, your personal income, etc.
              </>
            }
            name="otherAmount"
            saveInputValue={(newValue) =>
              onInputChange("otherAmount", newValue)
            }
          />

          <InputOption
            label="Student loans"
            form={form}
            currentCollegeData={selectedFinancialOptionsResult}
            tooltip={
              <>
                The amount you may expect to take out in student loans to pay
                for college.
              </>
            }
            name="studentLoanAmount"
            readOnly={true}
            saveInputValue={(newValue) =>
              onInputChange("studentLoanAmount", newValue)
            }
          />
        </div>
      </div>

      <Slider
        data={[
          {
            name: "Family contribution",
            defaultValue: Number(
              selectedFinancialOptionsResult.familyContributionAmount,
            ),
            bgColor: theme.colors.graphColors.turquoise,
          },
          {
            name: "Parent PLUS loans",
            defaultValue: Number(
              selectedFinancialOptionsResult.parentPlusLoanAmount,
            ),
            bgColor: theme.colors.graphColors.lavender,
          },
          {
            name: "Other",
            defaultValue: Number(selectedFinancialOptionsResult.otherAmount),
            bgColor: theme.colors.graphColors.mediumGreen,
          },
          {
            name: "Student loans",
            defaultValue: Number(
              selectedFinancialOptionsResult.studentLoanAmount,
            ),
            bgColor: theme.colors.graphColors.redOrange,
          },
        ]}
        onChange={onSliderChange}
        increment={500}
      />
    </Style>
  );
};

export default FinancingOptionsInputs;
